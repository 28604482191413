import { combineReducers } from '@reduxjs/toolkit';
import productsSlice from './slices/productsSlice';
import authSlice from './slices/authSlice';

const rootReducer = combineReducers({
  products: productsSlice,
  auth: authSlice,
});

export default rootReducer;
