import React, { useEffect, useState } from "react";
import giftStyles from './styles.module.css'
import dashStyles from '../Dashboard/styles.module.css'
import loginStyles from '../Login/styles.module.css'
import checkStyles from '../Checkout/styles.module.css'
import { Button } from '../../components'
import { accountBalance, checkBox, checkedBox, fawry, mastercard, meeza, toastGift, valu, visa, wallet } from '../../assets'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Gift({ pageID, activePageID }) {
  const [isActive, setIsActive] = useState(false);
  const [priceValue, setPriceValue] = useState(0);
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [isMobileChecked, setIsMobileChecked] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState("");

  useEffect(() => {
    if (activePageID === pageID) {
      setIsActive(true)
    } else {
      setIsActive(false);
    }
  }, [activePageID]);

  const notify = () => {
    toast(
      <div className="d-flex flex-column align-items-center">
        <img src={toastGift} alt="Gift" className="mb-2" />
        <p className={giftStyles.toast_message}>Your Gift Has been Sent Successfully</p>
      </div>, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      pauseOnFocusLoss: true,
      rtl: false,
      theme: "dark",
      className: `${giftStyles.Toastify__toast} pt-4 pb-3`,
      closeButton: `${giftStyles.Toastify__close}`,
    });
  };

  return (
    <div className={`${isActive ? dashStyles.active_container : dashStyles.in_active_container} col-11 col-md-8 flex-md-grow-1 gray_box px-2 px-md-4 py-4 align-self-lg-stretch`}>
      <div className="fit_box mb-4">
        <p className='dash_title mb-3'>send <span>a</span> gift !</p>
        <div className="horizontal_line" />
      </div>
      <div className="d-flex justify-content-between align-items-center me-lg-5 mb-2">
        <p className={`${giftStyles.price_title} mb-0`}>select gift price</p>
        <div className="d-flex justify-content-between align-items-center pe-lg-5 me-lg-5">
          <input type="tel" value={priceValue} onChange={(e) => setPriceValue(e.target.value)} className={`${giftStyles.price_input} py-2 me-2`} />
          <p className={`${giftStyles.price_currency} mb-0`}>egp</p>
        </div>
      </div>
      <p className={`${giftStyles.method_title} mb-0`}>select payment method</p>
      <div className={`${giftStyles.methods_container} ps-2 pe-2 pe-md-5 py-3 my-4`}>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div>
            <p className={`${checkStyles.payment_title} mb-1`}>instalments</p>
            <p className={`${checkStyles.payment_txt} mb-0`}>Pay with Installments</p>
          </div>
          <div className={checkStyles.plus_img_box}>
            <img src={valu} alt="valu logo" className="img" />
          </div>
          <input type="radio" id="instalments" className={checkStyles.radio_input} name="method" value='instalments' checked={selectedPayment === 'instalments'} onChange={(e) => setSelectedPayment(e.target.value)} />
          <label htmlFor="instalments" className={checkStyles.radio_btn} />
        </div>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div>
            <p className={`${checkStyles.payment_title} mb-1`}>credit/debit card</p>
            <p className={`${checkStyles.payment_txt} mb-0`}>Fees Apply</p>
          </div>
          <div className="d-flex">
            <img src={visa} alt="visa logo" className="me-2" />
            <img src={mastercard} alt="mastercard logo" className="me-2" />
            <img src={meeza} alt="meeza logo" />
          </div>
          <input type="radio" id="credit_debit" className={checkStyles.radio_input} name="method" value='credit/debit' checked={selectedPayment === 'credit/debit'} onChange={(e) => setSelectedPayment(e.target.value)} />
          <label htmlFor="credit_debit" className={checkStyles.radio_btn} />
        </div>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div>
            <p className={`${checkStyles.payment_title} mb-1`}>wallet</p>
            <p className={`${checkStyles.payment_txt} mb-0`}>Pay with your Wallet</p>
          </div>
          <div className={checkStyles.plus_img_box}>
            <img src={wallet} alt="wallet logo" className="img" />
          </div>
          <input type="radio" id="wallet" className={checkStyles.radio_input} name="method" value='wallet' checked={selectedPayment === 'wallet'} onChange={(e) => setSelectedPayment(e.target.value)} />
          <label htmlFor="wallet" className={checkStyles.radio_btn} />
        </div>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div>
            <p className={`${checkStyles.payment_title} mb-1`}>bank transfer</p>
            <p className={`${checkStyles.payment_txt} mb-0`}>Pay with Bank Account</p>
          </div>
          <div className={checkStyles.plus_img_box}>
            <img src={accountBalance} alt="bank account logo" className="img" />
          </div>
          <input type="radio" id="bank_transfer" className={checkStyles.radio_input} name="method" value='bank-transfer' checked={selectedPayment === 'bank-transfer'} onChange={(e) => setSelectedPayment(e.target.value)} />
          <label htmlFor="bank_transfer" className={checkStyles.radio_btn} />
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <p className={`${checkStyles.payment_title} mb-1`}>fawry</p>
            <p className={`${checkStyles.payment_txt} mb-0`}>Pay with Fawry</p>
          </div>
          <div className={checkStyles.fawry_img_box}>
            <img src={fawry} alt="fawry logo" className="img" />
          </div>
          <input type="radio" id="fawry" className={checkStyles.radio_input} name="method" value='fawry' checked={selectedPayment === 'fawry'} onChange={(e) => setSelectedPayment(e.target.value)} />
          <label htmlFor="fawry" className={checkStyles.radio_btn} />
        </div>
      </div>
      <p className={`${giftStyles.method_title} mb-3`}>select your send options for gift</p>
      <div className="d-flex align-items-center mb-2">
        <div onClick={() => setIsEmailChecked(!isEmailChecked)}>
          {isEmailChecked ? (
            <img src={checkedBox} alt="checked box" />
          ) : (
            <img src={checkBox} alt="check box" />
          )}
        </div>
        <p className={`${giftStyles.checkout_txt} ms-2 mb-0`}>email address</p>
      </div>
      <div className="d-flex align-items-center mb-2">
        <div onClick={() => setIsMobileChecked(!isMobileChecked)}>
          {isMobileChecked ? (
            <img src={checkedBox} alt="checked box" />
          ) : (
            <img src={checkBox} alt="check box" />
          )}
        </div>
        <p className={`${giftStyles.checkout_txt} ms-2 mb-0`}>mobile number</p>
      </div>
      <Button title={'send gift'} style={`${giftStyles.gift_btn} mt-3 px-5 py-2`} onClick={notify} />
      <ToastContainer />
    </div>
  );
}

export default Gift;
