import React from "react";
import newsStyles from './styles.module.css'

function NewsItem({ item, isLastItem }) {
  const colorClass = 
  item.color === 'green' ? newsStyles['percentage-green'] :
  item.color === 'red' ? newsStyles['percentage-red'] :
  newsStyles['percentage-normal'];

  return (
    <div key={item.id}>
      <div className={newsStyles.title_box}>
        <div className={newsStyles.img_box}>
          <img src={item.icon} alt="trejars bars" className={newsStyles.img} />
        </div>
        <p className={newsStyles.title}>{item.title}</p>
      </div>
        <p className={newsStyles.price}>{item.price}</p>
        <p className={`${newsStyles.percentage} ${colorClass}`}>{item.percentage}</p>
    </div>
  )
}

export default NewsItem;
