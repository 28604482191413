import React, { useEffect, useState } from "react";
import dashStyles from '../Dashboard/styles.module.css'
import wishStyles from './styles.module.css'
import { close, coin } from '../../assets'
import { Button } from '../../components'

function WishList({ pageID, activePageID }) {
  const [isActive, setIsActive] = useState(false);
  const [products, setProducts] = useState([{}, {}, {}]);

  useEffect(() => {
    if (activePageID === pageID) {
      setIsActive(true)
    } else {
      setIsActive(false);
    }
  }, [activePageID]);

  const removeProduct = (index) => {
    setProducts(products.filter((_, i) => i !== index))
  }

  return (
    <div className={`${isActive ? dashStyles.active_container : dashStyles.in_active_container} col-11 col-md-8 flex-md-grow-1 gray_box px-2 px-lg-3 pt-4 pb-3`}>
      <div className={wishStyles.title_box}>
        <p className='dash_title mb-3'>Your favorite order</p>
        <div className="horizontal_line" />
      </div>
      <div className="row mt-5 mb-1">
        <p className={`${wishStyles.titles} col`}>name <span>of product</span></p>
        <p className={`${wishStyles.titles} col`}>total</p>
        <p className={`${wishStyles.titles} col`}>action</p>
      </div>
      <div className="horizontal_line" />
      {products.map((item, index) => {
        return (
          <div className={`${wishStyles.wish_item} my-4 p-2`}>
            <div className="row align-items-center">
              <div className="col">
                <div className={wishStyles.img_box}>
                  <img src={coin} alt="product trejars" className="img" />
                </div>
                <p className={`${wishStyles.product_name} mt-2`}>21K gold pound from BTC</p>
              </div>
              <p className={`${wishStyles.product_price} col`}>24,740 egp</p>
              <div className="col d-flex justify-content-center">
                <div className={`${wishStyles.remove_icon_box}`} onClick={() => removeProduct(index)}>
                  <img src={close} alt="close" className="img" />
                </div>
              </div>
            </div>
          </div>
        )
      })}
      <div className="d-flex justify-content-end align-items-center me-sm-5">
        <p className={`${wishStyles.clean} mb-0`}>clean wishlist</p>
        <Button title={'view cart'} to={'/cart'} style={`${wishStyles.go_cart_btn} ms-4 px-5 py-2`} />
      </div>
    </div>
  );
}

export default WishList;
