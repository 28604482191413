import React from "react";
import aboutStyles from './styles.module.css'
import GPStyles from '../GoldPrice/styles.module.css'
import {AppBanner} from "../../components";
import { contactBanner, goldDoctor, neckless, work1, work2, work3 } from "../../assets";

function AboutUs() {
  return (
    <main className="bg_dark">
      <div className="mt-lg-4 mb-5">
        <AppBanner url={contactBanner} title={'about us'} />
      </div>
      {/* starting section */}
      <section>
        <div className="container">
          <div className={`${aboutStyles.start_container} row gx-0`}>
            <div className={`${aboutStyles.start_img_box} hidden col-12 col-lg-6`}>
              <img src={goldDoctor} alt='gold in hands' className='img cover' />
            </div>
            <div className="col-12 col-lg-6 px-4 py-5 py-lg-0 align-content-center">
              <p className={aboutStyles.start_content_how}>how we started</p>
              <p className={aboutStyles.start_content_title}>Unlocking new possibilities in gold</p>
              <p className={aboutStyles.start_content_txt}>Trejers Egyptian joint stock company 1-1-5555-24</p>
              <p className={aboutStyles.start_content_txt}>It was established in 2024. It obtained its licenses in accordance with Egyptian law from the Investment Authority, Stamp and Scales Authority No. 1-1-5555-24</p>
              <p className={aboutStyles.start_content_txt}>trejars works in the field of gold, 24 karat gold bullion, 21 karat gold coins, additional to silver</p>
            </div>
          </div>
        </div>
      </section>
      {/* work section */}
      <section className={aboutStyles.work_section}>
        <div className="container">
          <div className={GPStyles.prices_title_box}>
            <h3 className={GPStyles.prices_title}>
              how <span className='span'>we</span> work
            </h3>
          </div>
          <div className='row justify-content-center gy-5 gx-3 mb-5'>
            <div className={`${aboutStyles.card_box} col-12 col-md-4`}>
              <div className={aboutStyles.card_img_box}>
                <img src={work1} alt='gold bars' className='img cover' />
              </div>
              <p className={aboutStyles.card_title}>
                our principle
              </p>
              <p className={aboutStyles.card_txt}>
                Relentlessly pursuing customer satisfaction through continual improvement of investment strategies and fostering enduring, immediate partnerships.
              </p>
            </div>
            <div className={`${aboutStyles.card_box} col-12 col-md-4`}>
              <div className={aboutStyles.card_img_box}>
                <img src={work2} alt='gold target' className='img cover' />
              </div>
              <p className={aboutStyles.card_title}>
                target
              </p>
              <p className={aboutStyles.card_txt}>
                Our goal is to be your foremost authority and trusted resourse for all gold investments, insuring a secure avenue for confident financial endeavors.
              </p>
            </div>
            <div className={`${aboutStyles.card_box} col-12 col-md-4`}>
              <div className={aboutStyles.card_img_box}>
                <img src={work3} alt='gold' className='img cover' />
              </div>
              <p className={aboutStyles.card_title}>
                our vision
              </p>
              <p className={aboutStyles.card_txt}>
                We aspire to cultivate a culture of secure wealth accumulation by promoting investments in bullion and gold coins, fostering prosperity.
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AboutUs;
