import axios from 'axios';

const BASE_URL = 'https://trejars.com/backend/public/api/';

export const APIS = {
    // =================== auth =================== //
    // =================== products =================== //
    allProducts: 'products/all',
    productDetails: 'products/getProduct/',
};

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    timeout: 10000,
});

axiosInstance.interceptors.response.use(
    (res) => {
        return res;
    },
    (error) => {
        let err = error?.response?.data || 'Bad Request';
        console.log('Request Error ::', err);
        return Promise.reject(error);
    },
);

const Axios = async ({
    method,
    path,
    data,
    params,
    header = {},
    pathParams = '',
    isFormData = false,
}) => {
    const response = await axiosInstance.request({
        method: method,
        url: `${path}${pathParams}`,
        data: data,
        params: params,
        headers: {
            'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
            ...header,
        },
    });
    return response;
};

export default Axios;
