import React, { useState } from "react";
import productStyles from './styles.module.css'
import { cartProduct, eyeProduct, favoriteProduct } from "../../assets";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function ProductItem({ item, style, onSelect }) {
    const navigate = useNavigate();
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => setIsHovering(true);
    const handleMouseLeave = () => setIsHovering(false);

    const handleClick = () => {
        onSelect ? onSelect() : navigate('/productDetails', { state: { item } });
    }

    const isMobile = useMediaQuery({ maxWidth: "767px" });
    const isTablet = useMediaQuery({ maxWidth: "991px" });

    return (
        <div className={`${productStyles.productBox} ${style}`} onClick={handleClick} onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            {isHovering && !isMobile && (
                <div className={productStyles.product_icons}>
                    <div className={productStyles.product_icon}>
                        <img src={eyeProduct} alt='product-option' />
                    </div>
                    <div className={productStyles.product_icon}>
                        <img src={favoriteProduct} alt='product-option' />
                    </div>
                    <div className={productStyles.product_icon}>
                        <img src={cartProduct} alt='product-option' />
                    </div>
                </div>
            )}
            {isTablet && !isMobile && (
                <div className={productStyles.product_icons}>
                    <div className={productStyles.product_icon}>
                        <img src={eyeProduct} alt='product-option' />
                    </div>
                    <div className={productStyles.product_icon}>
                        <img src={favoriteProduct} alt='product-option' />
                    </div>
                    <div className={productStyles.product_icon}>
                        <img src={cartProduct} alt='product-option' />
                    </div>
                </div>
            )}
            <div className={productStyles.product_img_box}>
                <img src={item?.image} alt='trejars' className="img" />
            </div>
            <p className={productStyles.product_title}>{item?.name}</p>
            <p className={productStyles.product_now}>price now</p>
            <p className={productStyles.product_price}>{`${item?.priceAfter?.toFixed(2)} egp`}</p>
            {isMobile && (
                <div className="d-flex justify-content-between w-75 px-2">
                    <div className={productStyles.product_icon}>
                        <img src={eyeProduct} alt='product-option' />
                    </div>
                    <div className={productStyles.product_icon}>
                        <img src={favoriteProduct} alt='product-option' />
                    </div>
                    <div className={productStyles.product_icon}>
                        <img src={cartProduct} alt='product-option' />
                    </div>
                </div>
            )}
            <Button title={'buy now'} style={`${productStyles.btn} d-none d-md-block`} onClick={handleClick} />
        </div>
    )
}

export default ProductItem;
