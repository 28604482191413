import React, { useState } from "react";
import loginStyles from './styles.module.css';
import { apple, checkBox, checkedBox, eye, eyeSlash, facebookCircle, google, loginBanner, service3 } from "../../assets";
import { Button, InputField } from "../../components";
import { Link } from "react-router-dom";

function Login() {
    const [isChecked, setIsChecked] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <main>
            <div className="container pt-5 mt-5">
                <div className="row justify-content-center g-lg-0">
                    <div className={`${loginStyles.login_img} d-none d-lg-block col-lg-8`}>
                        <img src={loginBanner} alt="gold planet" className="img cover" />
                    </div>
                    <div className={`${loginStyles.login_content} col-11 col-md-7 col-lg-4 justify-content-center ps-4 pe-3 pb-3`}>
                        <p className={loginStyles.login_txt}>login</p>
                        <p className={loginStyles.welcome_txt}>TREJARS</p>
                        <p className={loginStyles.welcome_txt}>Welcome back</p>
                        <InputField
                            type={'text'}
                            fieldName={'email'}
                            label={'email'}
                            placeholder={'Enter Your Email'}
                            style={loginStyles.field_box}
                            inputStyle={loginStyles.input}
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                        <InputField
                            type={!isPasswordVisible ? 'password' : 'text'}
                            fieldName={'password'}
                            label={'password'}
                            placeholder={'Enter Your Password'}
                            style={loginStyles.field_box}
                            inputStyle={loginStyles.input}
                            value={formData.password}
                            onChange={handleInputChange}
                            rightIcon={isPasswordVisible ? <img src={eye} alt="eye" className="img" /> : <img src={eyeSlash} alt="eye-slash" className="img" />}
                            onRightIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        />
                        <div className="d-flex justify-content-between align-items-center px-2 mb-3">
                            <div className="d-flex align-items-center c-pointer" onClick={() => setIsChecked(!isChecked)}>
                                {isChecked ? (
                                    <img src={checkedBox} alt="checked box" />
                                ) : (
                                    <img src={checkBox} alt="check box" />
                                )}
                                <p className={`${loginStyles.rem_txt} ms-2 mb-0`}>Remember me</p>
                            </div>
                            <p className={`${loginStyles.forgot_txt} mb-0`}>Forgot your password?</p>
                        </div>
                        <Button to='/home' title={'login'} style={loginStyles.login_btn} />
                        <p className={loginStyles.login_with_txt}>or login with</p>
                        <div className="d-flex justify-content-center mb-3">
                            <div className={loginStyles.icon_box}>
                                <img src={apple} alt="apple" className="img cover" />
                            </div>
                            <div className={loginStyles.icon_box}>
                                <img src={google} alt="gmail" className="img cover" />
                            </div>
                            <div className={loginStyles.icon_box}>
                                <img src={facebookCircle} alt="facebook" className="img cover" />
                            </div>
                        </div>
                        <p className={loginStyles.sign_txt}>Don't have Account? <Link to={'/signUp'} className={loginStyles.sign_txt2}> let's sign up</Link></p>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Login;
