import React, { useEffect, useState } from "react";
import navStyles from './styles.module.css'
import { chevronRight, close, navCart, navFavorite, navLogo, navMenu, navSearch, navUser } from "../../assets";
import { Link, NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Button } from '../'

function Nav({ isDashboardOpen, isDashMenuOpen, setIsDashMenuOpen }) {
    const [searchValue, setSearchValue] = useState('');
    const [showMenu, setShowMenu] = useState(false);

    const searchFunction = () => {
        setSearchValue('')
    };

    const isMobile = useMediaQuery({ maxWidth: "767px" });

    useEffect(() => {
        if (!isMobile) {
            setShowMenu(false);
        }
    }, [isMobile]);

    return (
        <nav className={`${navStyles.nav_Container}`}>
            <div className="container">
                <div className={`${navStyles.language_Line} row justify-content-between align-items-center`}>
                    <p className={`${navStyles.language} col`}>العربية</p>
                    <div className='d-flex col justify-content-end'>
                        <Link to={'/dashboard'} className={`${navStyles.my_account} d-none d-sm-block`}>my account</Link>
                        <Link to={'/dashboard'} state={5} >
                            <img src={navFavorite} alt="heart-icon" className={navStyles.nav_small_icon} />
                        </Link>
                        <Link to={'/dashboard'} state={2} >
                            <img src={navUser} alt="account-icon" className={navStyles.nav_small_icon} />
                        </Link>
                        <Link to={'cart'} >
                            <img src={navCart} alt="cart-icon" className={navStyles.nav_small_icon} />
                        </Link>
                    </div>
                </div>
            </div>
            <div className={`${navStyles.nav_Box}`}>
                <div className="container">
                    <div className="row justify-content-between align-items-center gx-5">
                        <figure className={`${navStyles.nav_log_Box} col-4 col-md-2 col-xlg-1`}>
                            <img src={navLogo} alt="site-Logo" className={navStyles.nav_logo} />
                        </figure>
                        <ul className="my-md-0 justify-content-md-between align-items-md-center d-none d-md-flex col-md-6 flex-md-grow-1 flex-xlg-grow-0 list-unstyled">
                            <li><NavLink to='home' className={({ isActive }) => (isActive ? navStyles.active_link : navStyles.nav_txt)}>home</NavLink></li>
                            <li><NavLink to='goldPrice' className={({ isActive }) => (isActive ? navStyles.active_link : navStyles.nav_txt)}>gold price</NavLink></li>
                            <li><NavLink to='shop' className={({ isActive }) => (isActive ? navStyles.active_link : navStyles.nav_txt)}>shop</NavLink></li>
                            <li><NavLink to='aboutUs' className={({ isActive }) => (isActive ? navStyles.active_link : navStyles.nav_txt)}>about us</NavLink></li>
                            <li><NavLink to='goldPriceHistory' className={({ isActive }) => (isActive ? navStyles.active_link : navStyles.nav_txt)}>gold price history</NavLink></li>
                        </ul>
                        <div className={`d-none d-md-block col-md-3 col-xlg-2 ms-lg-4 ${navStyles.search_box_container}`}>
                            <div className={`${navStyles.search_box} d-none d-md-flex ps-2 pe-3 px-lg-3`}>
                                <input type="text" className={`${navStyles.search_input} col-11`} placeholder="search products" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                <button className={`${navStyles.search_btn} col-1`} onClick={searchFunction}>
                                    <img src={navSearch} alt="search-icon" className={navStyles.small_Img} />
                                </button>
                            </div>
                        </div>
                        <div className={`${navStyles.nav_toggle} ${showMenu ? navStyles.open_toggle : navStyles.nav_toggle} d-md-none col-2`} onClick={() => {
                            setShowMenu(!showMenu);
                            setIsDashMenuOpen(!isDashMenuOpen)
                        }}>
                            <img src={navMenu} alt="burger menu" className="img" />
                        </div>
                    </div>
                    {/* mobile screen navbar */}
                    {showMenu && !isDashboardOpen && (
                        <div className={`${navStyles.menu} px-5 py-4 w-100 d-flex flex-column`}>
                            <div className={navStyles.close_box} onClick={() => {
                                setShowMenu(false);
                                setIsDashMenuOpen(false)
                            }}>
                                <img src={close} alt="close" className="img" />
                            </div>
                            <div className={`${navStyles.search_box_container} my-4`}>
                                <div className={`${navStyles.search_box} w-100 d-flex ps-2 pe-3`}>
                                    <input type="text" className={`${navStyles.search_input} col-11`} placeholder="search products" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                    <button className={`${navStyles.search_btn} col-1`} onClick={searchFunction}>
                                        <img src={navSearch} alt="search-icon" className={navStyles.small_Img} />
                                    </button>
                                </div>
                            </div>
                            <p className={navStyles.menu_txt}>main menu</p>
                            <ul className="mb-4 mt-4 list-unstyled d-flex flex-column align-items-center">
                                <li className={`${navStyles.mobile_link} d-flex w-100 justify-content-between align-items-center mb-4 pb-3`}>
                                    <NavLink to='home'
                                        className={navStyles.nav_txt}
                                        onClick={() => {
                                            setShowMenu(false);
                                            window.scrollTo(0, 0);
                                        }}
                                    >home</NavLink>
                                    <div className={navStyles.chevron_box}>
                                        <img src={chevronRight} alt="chevron right" className="img" />
                                    </div>
                                </li>
                                <li className={`${navStyles.mobile_link} d-flex w-100 justify-content-between align-items-center mb-4 pb-3`}>
                                    <NavLink to='goldPrice'
                                        className={navStyles.nav_txt}
                                        onClick={() => {
                                            setShowMenu(false);
                                            window.scrollTo(0, 0);
                                        }}
                                    >gold price</NavLink>
                                    <div className={navStyles.chevron_box}>
                                        <img src={chevronRight} alt="chevron right" className="img" />
                                    </div>
                                </li>
                                <li className={`${navStyles.mobile_link} d-flex w-100 justify-content-between align-items-center mb-4 pb-3`}>
                                    <NavLink to='shop'
                                        className={navStyles.nav_txt}
                                        onClick={() => {
                                            setShowMenu(false);
                                            window.scrollTo(0, 0);
                                        }}
                                    >shop</NavLink>
                                    <div className={navStyles.chevron_box}>
                                        <img src={chevronRight} alt="chevron right" className="img" />
                                    </div>
                                </li>
                                <li className={`${navStyles.mobile_link} d-flex w-100 justify-content-between align-items-center mb-4 pb-3`}>
                                    <NavLink to='aboutUs'
                                        className={navStyles.nav_txt}
                                        onClick={() => {
                                            setShowMenu(false);
                                            window.scrollTo(0, 0);
                                        }}
                                    >about us</NavLink>
                                    <div className={navStyles.chevron_box}>
                                        <img src={chevronRight} alt="chevron right" className="img" />
                                    </div>
                                </li>
                                <li className={`${navStyles.mobile_link} d-flex w-100 justify-content-between align-items-center mb-4 pb-3`}>
                                    <NavLink to='contactUs'
                                        className={navStyles.nav_txt}
                                        onClick={() => {
                                            setShowMenu(false);
                                            window.scrollTo(0, 0);
                                        }}
                                    >contact us</NavLink>
                                    <div className={navStyles.chevron_box}>
                                        <img src={chevronRight} alt="chevron right" className="img" />
                                    </div>
                                </li>
                                <li className={`${navStyles.mobile_link} d-flex w-100 justify-content-between align-items-center mb-4 pb-3`}>
                                    <NavLink to='goldPriceHistory'
                                        className={navStyles.nav_txt}
                                        onClick={() => {
                                            setShowMenu(false);
                                            window.scrollTo(0, 0);
                                        }}
                                    >gold price history</NavLink>
                                    <div className={navStyles.chevron_box}>
                                        <img src={chevronRight} alt="chevron right" className="img" />
                                    </div>
                                </li>
                                <li className={`${navStyles.mobile_link} d-flex w-100 justify-content-between align-items-center mb-4 pb-3`}>
                                    <NavLink to='./dashboard'
                                        className={navStyles.nav_txt}
                                        onClick={() => {
                                            setShowMenu(false);
                                            window.scrollTo(0, 0);
                                        }}
                                    >my account</NavLink>
                                    <div className={navStyles.chevron_box}>
                                        <img src={chevronRight} alt="chevron right" className="img" />
                                    </div>
                                </li>
                            </ul>
                            <div className="d-flex align-items-center justify-content-center">
                                <Button title={'login'} to={'login'}
                                    onClick={() => {
                                        setShowMenu(false);
                                        window.scrollTo(0, 0)
                                    }}
                                    style={`${navStyles.secondry_btn} px-4 py-1 me-4`}
                                />
                                <Button title={'sign up'} to={'signUp'}
                                    style={`px-4 py-1`}
                                    onClick={() => {
                                        setShowMenu(false);
                                        window.scrollTo(0, 0)
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    )
}

export default Nav;
