import React, { useEffect, useRef } from "react";
import compareStyles from "./styles.module.css";
import homeStyles from "../Home/styles.module.css";
import cartStyles from "../Cart/styles.module.css";
import { arrowSquareLeft, arrowSquareRight, close, coin } from "../../assets";
import { Button, MayLikeItem, ProductItem } from "../../components";
import { LIKESDATA } from "../../utils/data";
import { handleWheel, scroll } from "../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { GetAllProducts } from "../../redux/slices/productsSlice";

function Compare() {
    const productsRef = useRef(null);
    const likesRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAllProducts());
    }, []);

    const { loading, allProducts } = useSelector(state => state.products);

    return (
        <main>
            <section className="container mt-5">
                <div className="row justify-content-center">
                    <div className="gray_box col-11 col-md-5 flex-grow-0 flex-md-grow-1 px-3 py-4 mb-4 mb-md-0 me-md-3">
                        <div className="d-flex justify-content-end">
                            <div className={`${compareStyles.close_box}`}>
                                <img src={close} alt="close" className="img" />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center my-3 me-md-4 me-lg-5">
                            <p className={`${compareStyles.product} mb-0`}>product</p>
                            <Button title={'add to cart'} style={`${compareStyles.add_cart} px-5 py-2 me-lg-5`} />
                        </div>
                        <div className="d-flex justify-content-around align-items-center">
                            <div className="d-flex flex-column align-items-center">
                                <div className={`${compareStyles.product_img_box}`}>
                                    <img src={coin} alt="coin" className="img" />
                                </div>
                                <p className={`${compareStyles.data_txt} mb-0`}>Necklace and Earring Set</p>
                            </div>
                            <p className={`${compareStyles.data_txt} mb-0`}>24,740 EGP</p>
                        </div>
                        <div className="mt-4">
                            <div className="row mb-2">
                                <p className={`${compareStyles.data_title} col mb-0`}>code</p>
                                <p className={`${compareStyles.data_txt} col mb-0`}>NEAR-1301135</p>
                            </div>
                            <div className="row mb-2">
                                <p className={`${compareStyles.data_title} col mb-0`}>color</p>
                                <p className={`${compareStyles.data_txt} col mb-0`}>Yellow Gold</p>
                            </div>
                            <div className="row mb-2">
                                <p className={`${compareStyles.data_title} col mb-0`}>type</p>
                                <p className={`${compareStyles.data_txt} col mb-0`}>Necklace and Earring Set</p>
                            </div>
                            <div className="row mb-2">
                                <p className={`${compareStyles.data_title} col mb-0`}>age group</p>
                                <p className={`${compareStyles.data_txt} col mb-0`}>Adult</p>
                            </div>
                            <div className="row mb-2">
                                <p className={`${compareStyles.data_title} col mb-0`}>availability</p>
                                <p className={`${compareStyles.data_txt} col mb-0`}>In Stock</p>
                            </div>
                            <div className="row mb-0">
                                <p className={`${compareStyles.data_title} col mb-0 mb-0`}>item origin</p>
                                <p className={`${compareStyles.data_txt} col mb-0 mb-0`}>Egypt</p>
                            </div>
                        </div>
                    </div>
                    <div className="gray_box col-11 col-md-5 flex-grow-0 flex-md-grow-1 px-3 py-4">
                        <div className="d-flex justify-content-end">
                            <div className={`${compareStyles.close_box}`}>
                                <img src={close} alt="close" className="img" />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center my-3 me-md-4 me-lg-5">
                            <p className={`${compareStyles.product} mb-0`}>product</p>
                            <Button title={'add to cart'} style={`${compareStyles.add_cart} px-5 py-2 me-lg-5`} />
                        </div>
                        <div className="d-flex justify-content-around align-items-center">
                            <div className="d-flex flex-column align-items-center">
                                <div className={`${compareStyles.product_img_box}`}>
                                    <img src={coin} alt="coin" className="img" />
                                </div>
                                <p className={`${compareStyles.data_txt} mb-0`}>Necklace and Earring Set</p>
                            </div>
                            <p className={`${compareStyles.data_txt} mb-0`}>24,740 EGP</p>
                        </div>
                        <div className="mt-4">
                            <div className="row mb-2">
                                <p className={`${compareStyles.data_title} col mb-0`}>code</p>
                                <p className={`${compareStyles.data_txt} col mb-0`}>NEAR-1301135</p>
                            </div>
                            <div className="row mb-2">
                                <p className={`${compareStyles.data_title} col mb-0`}>color</p>
                                <p className={`${compareStyles.data_txt} col mb-0`}>Yellow Gold</p>
                            </div>
                            <div className="row mb-2">
                                <p className={`${compareStyles.data_title} col mb-0`}>type</p>
                                <p className={`${compareStyles.data_txt} col mb-0`}>Necklace and Earring Set</p>
                            </div>
                            <div className="row mb-2">
                                <p className={`${compareStyles.data_title} col mb-0`}>age group</p>
                                <p className={`${compareStyles.data_txt} col mb-0`}>Adult</p>
                            </div>
                            <div className="row mb-2">
                                <p className={`${compareStyles.data_title} col mb-0`}>availability</p>
                                <p className={`${compareStyles.data_txt} col mb-0`}>In Stock</p>
                            </div>
                            <div className="row mb-0">
                                <p className={`${compareStyles.data_title} col mb-0 mb-0`}>item origin</p>
                                <p className={`${compareStyles.data_txt} col mb-0 mb-0`}>Egypt</p>
                            </div>
                        </div>
                    </div>
                    <Button title={'add product to compare'} style={`col-10 col-md-4 py-2 my-4`} />
                </div>
                <div className="horizontal_line d-block d-lg-none" />
            </section>
            <div className="horizontal_line d-none d-lg-block mb-5" />
            {/* products */}
            <section className={`${homeStyles.products} mb-5`}>
                <div className="container">
                    <div className={homeStyles.trends_title_box}>
                        <h3 className={`${homeStyles.trends_title} mb-1`}>products selection</h3>
                    </div>
                    <div className={`${homeStyles.products_box} d-flex`} ref={productsRef}>
                        {allProducts?.map((item) => <ProductItem item={item} />)}
                    </div>
                    <div className={homeStyles.products_btns_box}>
                        <div onClick={() => scroll(-70, productsRef)} className={homeStyles.products_direction}
                        ><img src={arrowSquareLeft} alt="arrow left" className="img" />
                        </div>
                        <div onClick={() => scroll(70, productsRef)} className={homeStyles.products_direction}>
                            <img src={arrowSquareRight} alt="arrow right" className="img" />
                        </div>
                    </div>
                </div>
            </section >
            {/* may_like */}
            <section className={cartStyles.may_like}>
                <div className="container">
                    <p className={cartStyles.may_title}>you may like</p>
                    <div className={`${cartStyles.likes_box} d-flex mt-0`} onWheel={(event) => handleWheel(event, likesRef)} ref={likesRef}>
                        {LIKESDATA.map((item) => <MayLikeItem item={item} />)}
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Compare;
