import React, { useState } from "react";
import homeStyles from '../Home/styles.module.css'
import { AppBanner, Button, InputField } from "../../components";
import { call, contactBanner, facebook, instagram, linkedin, location, mail, twitter, youtube } from "../../assets";

function ContactUs() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        message: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const submitContact = () => {
        console.log('formData', formData);
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            company: '',
            message: '',
        })
    }
    return (
        <main>
            <div className="mt-lg-4 mb-5">
                <AppBanner page={3} title={'contact us'} url={contactBanner} />
            </div>
            <section className={homeStyles.contact} >
                <div className="container">
                    <div className={`row ${homeStyles.contact_gap}`}>
                        <div className={`${homeStyles.contact_box} col-12 col-md-5 flex-md-grow-1 order-last order-md-first`}>
                            <p className={homeStyles.contact_us_title}>contact with uS</p>
                            <p className={homeStyles.contact_us_txt}>Please feel free to contact us regarding any concerns or inquiries.</p>
                            <div className={`d-flex flex-column flex-lg-row ${homeStyles.inputs_box}`}>
                                <InputField
                                    type={'text'}
                                    fieldName={'firstName'}
                                    label={'first name'}
                                    placeholder={'Enter your first name'}
                                    style={homeStyles.half_input}
                                    inputStyle={`${homeStyles.contact_input} col-12`}
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                />
                                <InputField
                                    type={'text'}
                                    fieldName={'lastName'}
                                    label={'last name'}
                                    placeholder={'Enter your last name'}
                                    style={homeStyles.half_input}
                                    inputStyle={`${homeStyles.contact_input} col-12`}
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <InputField
                                type={'email'}
                                fieldName={'email'}
                                label={'email'}
                                placeholder={'Enter your Email'}
                                style={homeStyles.contact_field}
                                inputStyle={homeStyles.contact_input}
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                            <InputField
                                type={'text'}
                                fieldName={'phone'}
                                label={'phone number'}
                                placeholder={'Enter your phone number'}
                                style={homeStyles.contact_field}
                                inputStyle={homeStyles.contact_input}
                                value={formData.phone}
                                onChange={handleInputChange}
                            />
                            <InputField
                                type={'text'}
                                fieldName={'company'}
                                label={'company name'}
                                placeholder={'Enter your Company Name'}
                                style={homeStyles.contact_field}
                                inputStyle={homeStyles.contact_input}
                                value={formData.company}
                                onChange={handleInputChange}
                            />
                            <InputField
                                isTextarea={true}
                                rows={8}
                                cols={2}
                                fieldName={'message'}
                                label={'message'}
                                placeholder={'Enter Your Message'}
                                style={homeStyles.contact_field}
                                inputStyle={homeStyles.contact_input}
                                value={formData.message}
                                onChange={handleInputChange}
                            />
                            <Button title={'send'} style={homeStyles.contact_btn} onClick={submitContact} />
                        </div>
                        <div className={`${homeStyles.contact_box} col-12 col-md-5 flex-md-grow-1 order-first order-md-last`}>
                            <p className={homeStyles.contact_info_title}>contact information</p>
                            <p className={homeStyles.contact_info_txt}>Have a question or a message for us? we're here to help! Feel free to share your thoughts, inquiries, or feedback in the form below. we'll get back to you as soon as possible. Your input is important to us!
                            </p>
                            <div className={`${homeStyles.contact_boxes} mb-3 mb-lg-4`}>
                                <div className={homeStyles.contact_boxes_img}>
                                    <img src={mail} alt='mail' className='img' />
                                </div>
                                <div>
                                    <p className={homeStyles.contact_boxes_title}>Chat with us</p>
                                    <p className={homeStyles.contact_boxes_txt}>Reach out to us at help@trejars.com or trejarsadmn@gmail.com</p>
                                </div>
                            </div>
                            <div className={`${homeStyles.contact_boxes} mb-3 mb-lg-4`}>
                                <div className={homeStyles.contact_boxes_img}>
                                    <img src={location} alt='location' className='img' />
                                </div>
                                <div>
                                    <p className={homeStyles.contact_boxes_title}>Visit us</p>
                                    <p className={homeStyles.contact_boxes_txt}>Second District, Building 7 , Lijanda Village , Sheikh Zayed City, Giza Egypt</p>
                                </div>
                            </div>
                            <div className={`${homeStyles.contact_boxes} mb-3 mb-lg-4`}>
                                <div className={homeStyles.contact_boxes_img}>
                                    <img src={call} alt='call' className='img' />
                                </div>
                                <div>
                                    <p className={homeStyles.contact_boxes_title}>Call us</p>
                                    <p className={homeStyles.contact_boxes_txt}>Ring US on +20 109 401 1111</p>
                                </div>
                            </div>
                            <div className={homeStyles.contact_social_icons}>
                                <div className={homeStyles.contact_icon_box}>
                                    <img src={linkedin} alt="linkedin" className="img" />
                                </div>
                                <div className={homeStyles.contact_icon_box}>
                                    <img src={twitter} alt="twitter" className="img" />
                                </div>
                                <div className={homeStyles.contact_icon_box}>
                                    <img src={instagram} alt="instagram" className="img" />
                                </div>
                                <div className={homeStyles.contact_icon_box}>
                                    <img src={facebook} alt="facebook" className="img" />
                                </div>
                                <div className={homeStyles.contact_icon_box}>
                                    <img src={youtube} alt="youtube" className="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </main>
    );
}

export default ContactUs;
