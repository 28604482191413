import React, { useEffect, useRef } from "react";
import GPStyles from './styles.module.css';
import homeStyles from '../Home/styles.module.css';
import { arrowSquareLeft, arrowSquareRight, banner1, info, service3 } from "../../assets";
import { CategoryScale, Chart } from 'chart.js/auto';
import { Line } from "react-chartjs-2";
import { AppBanner, ProductItem } from "../../components";
import { scroll } from "../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { GetAllProducts } from "../../redux/slices/productsSlice";

Chart.register(CategoryScale);

const EG_PRICES = [
  ['4,120 egp', '4,800 egp', '32,000 egp', '150,000 egp'],
  ['4,000 egp', '4,750 egp', '31,890 egp', '149,900 egp']
];

function GoldPrice() {
  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "June"];
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "",
        backgroundColor: '#FCC65B80',
        borderColor: "#A7833F",
        data: [2020, 2030, 2020, 2060, 2040, 2050],
        fill: false,
      },
    ],
  };

  const dispatch = useDispatch();
  const productsRef = useRef(null);
  const intervalId = useRef(null);

  useEffect(() => {
    dispatch(GetAllProducts());
    const reFetchData = () => {
      dispatch(GetAllProducts());
    };
    intervalId.current = setInterval(reFetchData, 30000);
    return () => clearInterval(intervalId.current);
  }, []);

  const { loading, allProducts } = useSelector(state => state.products);

  return (
    <main className="bg_dark">
      <div className="mt-lg-4 mb-5">
        <AppBanner url={banner1} title={'gold price'} />
      </div>
      {/* eg_prices_section */}
      <section className={GPStyles.eg_prices_section}>
        <div className="container">
          <div className={GPStyles.prices_title_box}>
            <h3 className={GPStyles.prices_title}>
              local gold price today <span className='span'>in</span> egypt
            </h3>
          </div>
          <table className={GPStyles.eg_prices_table}>
            <thead>
              <tr>
                <th>type</th>
                <th>local gold 21</th>
                <th>local gold 24</th>
                <th>local gold pound</th>
                <th>local gold ounce</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={GPStyles.table_tag}>sell</td>
                <td>{EG_PRICES[0][0]}</td>
                <td>{EG_PRICES[0][1]}</td>
                <td>{EG_PRICES[0][2]}</td>
                <td>{EG_PRICES[0][3]}</td>
              </tr>
              <tr>
                <td className={GPStyles.table_tag}>buy</td>
                <td>{EG_PRICES[1][0]}</td>
                <td>{EG_PRICES[1][1]}</td>
                <td>{EG_PRICES[1][2]}</td>
                <td>{EG_PRICES[1][3]}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      {/* charts_section */}
      <section>
        <div className="container">
          <div className={GPStyles.prices_title_box}>
            <h3 className={GPStyles.prices_title}>
              international gold price <span className='span'>per</span> ounce
            </h3>
          </div>
          <div className={GPStyles.charts_container}>
            <div className={`${GPStyles.chart_upper} d-flex justify-content-between align-items-center`}>
              <div>
                <p className={GPStyles.charts_title}>international gold price chart</p>
                <p className={GPStyles.charts_txt}>gold price <span className='span'>per</span> ounce</p>
              </div>
              <div className={GPStyles.charts_icon_box}>
                <img src={info} alt="help info" className="img" />
              </div>
            </div>
            <div className={`${GPStyles.line_chart_box}`}>
              <Line
                data={chartData}
                className={GPStyles.line_chart}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        color: '#FCC65B50',
                        tickBorderDash: [0.01, 0.01],
                      },
                      ticks: {
                        color: '#FCC65B80',
                      },
                    },
                    y: {
                      grid: {
                        color: '#FCC65B50',
                        tickBorderDash: [0.01, 0.01],
                      },
                      ticks: {
                        color: '#FCC65B',
                        callback: function (value) {
                          return value;
                        },
                      },
                      min: 2010,
                      max: 2070,
                    }
                  }
                }} />
            </div>
          </div>
        </div>
      </section>
      {/* products */}
      <section className={`${homeStyles.products} mb-5`}>
        <div className="container">
          <div className={homeStyles.trends_title_box}>
            <h3 className={`${homeStyles.trends_title} mb-1`}>products selection</h3>
          </div>
          <div className={`${homeStyles.products_box} d-flex`} ref={productsRef}>
            {allProducts?.map((item) => <ProductItem item={item} key={item.id} />)}
          </div>
          <div className={homeStyles.products_btns_box}>
            <div onClick={() => scroll(-70, productsRef)} className={homeStyles.products_direction}
            ><img src={arrowSquareLeft} alt="arrow left" className="img" />
            </div>
            <div onClick={() => scroll(70, productsRef)} className={homeStyles.products_direction}>
              <img src={arrowSquareRight} alt="arrow right" className="img" />
            </div>
          </div>
        </div>
      </section >
    </main>
  );
}

export default GoldPrice;
