import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios, { APIS } from '../../utils/axiosConf';

const initialState = {
    allProducts: [],
    productDetails: {},
    loading: false,
};

// --------------- GetAllProducts -----------------
export const GetAllProducts = createAsyncThunk(
    'products/all',
    async (_, { rejectWithValue }) => {
        try {
            const res = await Axios({
                method: 'GET',
                path: APIS.allProducts,
            });
            console.log('Get All Products', res?.data);
            return res.data;
        } catch (error) {
            console.log(
                'Get All Products Error: ' + JSON.stringify(error?.response?.data),
            );
            return rejectWithValue(error);
        }
    },
);
// --------------- GetProductDetails -----------------
export const GetProductDetails = createAsyncThunk(
    'products/details',
    async (id, { rejectWithValue }) => {
        try {
            const res = await Axios({
                method: 'GET',
                path: APIS.productDetails,
                pathParams: id,
            });
            console.log(`Product details ID:${id}`, res?.data);
            return res.data;
        } catch (error) {
            console.log(`Products details ID:${id}`, error);
            return rejectWithValue(error);
        }
    },
);

const productsSlice = createSlice({
    name: 'products',
    initialState: initialState,
    reducers: {
    },
    extraReducers: builder => {
        // --------------- GetAllProducts-----------------
        builder.addCase(GetAllProducts.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(GetAllProducts.fulfilled, (state, action) => {
            state.allProducts = action.payload;
            state.loading = false;
        });
        builder.addCase(GetAllProducts.rejected, (state, action) => {
            state.loading = false;
        });
        // --------------- GetProductDetails -----------------
        builder.addCase(GetProductDetails.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(GetProductDetails.fulfilled, (state, action) => {
            state.productDetails = action.payload;
            state.loading = false;
        });
        builder.addCase(GetProductDetails.rejected, (state, action) => {
            state.loading = false;
        });
    },
});

export const { } = productsSlice.actions;

export default productsSlice.reducer;
