import React from "react";
import inputStyles from './styles.module.css'

function InputField({ label, placeholder, value, onChange, type, fieldName, style, inputStyle, isTextarea, rows, cols, rightIcon, onRightIconClick, listName, isList, list }) {
    return (
        <div className={`${inputStyles.fieldContainer} ${style}`}>
            {label && <label htmlFor={fieldName} className={inputStyles.input_label}>{label}</label>}
            {isTextarea ? (
                <textarea id={fieldName} name={fieldName} value={value} onChange={onChange} placeholder={placeholder} className={`${inputStyles.input} ${inputStyle}`} rows={rows} cols={cols}></textarea>
            ) : isList ? (
                <select className={`${inputStyles.input} ${inputStyles.list_input} ${inputStyle}`}>
                    <option value="" className={inputStyles.list_placeholder}>{placeholder}</option>
                    {list.map((item) => <option value="item" className={inputStyles.list_option}>{item}</option>)}
                </select>
            ) : (
                <>
                    {type === 'file' ? (
                        <div className={inputStyles.file_input}>
                            <input
                                type={type}
                                id={fieldName}
                                style={{ display: 'none' }}
                                onChange={onChange}
                                name={fieldName}
                            />
                            <div className="d-flex align-items-baseline mb-3">
                                <label htmlFor={fieldName} className={inputStyles.file_btn}>
                                    browes
                                </label>
                                <p className={inputStyles.file_value}>{value ? value : placeholder}</p>
                            </div>
                        </div>
                    ) : (
                        <input id={fieldName} type={type} name={fieldName} value={value} onChange={onChange} placeholder={placeholder} className={`${inputStyles.input} ${inputStyle}`} />
                    )}
                </>
            )}
            {rightIcon && (
                <div onClick={onRightIconClick} className={inputStyles.eye_box}>
                    {rightIcon}
                </div>
            )}
        </div>
    )
}

export default InputField;
