import React, { useEffect, useState } from "react";
import dashStyles from '../Dashboard/styles.module.css'
import historyStyles from './styles.module.css'
import { Button } from "../../components";
import { download } from "../../assets";

const ORDERS = [
  { id: '#03658', name: '21k gold pound from BTC', total: '24,740 Egp', status: 'Delivered' },
  { id: '#03658', name: '21k gold pound from BTC', total: '24,740 Egp', status: 'Delivered' },
  { id: '#03658', name: '21k gold pound from BTC', total: '24,740 Egp', status: 'Delivered' },
  { id: '#03658', name: '21k gold pound from BTC', total: '24,740 Egp', status: 'Delivered' },
]

function OrderHistory({ pageID, activePageID, styles }) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (activePageID === pageID) {
      setIsActive(true)
    } else {
      setIsActive(false);
    }
  }, [activePageID]);

  return (
    <>
      <div className={`${isActive ? dashStyles.active_container : dashStyles.in_active_container} col-11 col-md-8 flex-md-grow-1 gray_box px-2 px-lg-3 pt-4 pb-3 d-none d-md-block ${styles}`}>
        <p className='dash_title mb-5'>order history</p>
        <div className="row">
          <p className={`${historyStyles.table_title} col-2 col-lg-1`}>product ID</p>
          <p className={`${historyStyles.table_title} col-3`}>name <span>of</span> product</p>
          <p className={`${historyStyles.table_title} col-1 col-lg-2`}>total</p>
          <p className={`${historyStyles.table_title} col-2`}>status</p>
          <p className={`${historyStyles.table_title} col-2`}>view</p>
          <p className={`${historyStyles.table_title} col-2`}>Download Invoice</p>
        </div>
        {ORDERS.map((item, index) =>
          <div className="row my-4" key={index}>
            <p className={`${historyStyles.table_data} col-2 col-lg-1`}>{item.id}</p>
            <p className={`${historyStyles.table_data} col-3`}>{item.name}</p>
            <p className={`${historyStyles.table_data} col-1 col-lg-2`}>{item.total}</p>
            <p className={`${historyStyles.table_data} col-2`}>{item.status}</p>
            <div className="col-2">
              <Button title={'view'} style={`${historyStyles.view_btn} w-100 py-1`} />
            </div>
            <div className="col-2 d-flex justify-content-center">
              <div className={historyStyles.download_box}>
                <img src={download} alt="download logo" className="img" />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={`col-11 gray_box py-4 d-block d-md-none px-3 ${styles}`}>
        <p className='dash_title mb-5'>order history</p>
        {ORDERS.map((item, index) =>
          <div>
            <div className="row align-items-center my-3 mx-0">
              <div className="col-8">
                <p className={`${historyStyles.mob_name} mb-3`}>{item.name}</p>
                <p className={`${historyStyles.mob_status} mb-0`}>{item.status}</p>
              </div>
              <Button title={'view'} style={`${historyStyles.view_btn} col-4 py-2`} />
            </div>
            <div className="horizontal_line" />
          </div>
        )}
      </div>
    </>
  );
}

export default OrderHistory;
