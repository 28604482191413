import React, { useState } from "react";
import checkStyles from './styles.module.css';
import { accountBalance, addBtn, bank, checkout, fawry, mastercard, meeza, shipCar, valu, visa, wallet } from '../../assets'
import { Button } from '../../components'

function Checkout() {
    const [selectedPayment, setSelectedPayment] = useState("");
    return (
        <main>
            <section className="container">
                <div className={"d-flex flex-column align-items-center my-4 py-3"}>
                    <div className={checkStyles.img_box}>
                        <img src={checkout} alt="checkout cart" className="img" />
                    </div>
                    <p className={`${checkStyles.checkout_title} mt-3`}>check <span>out</span></p>
                </div>
                <div className={`gray_box px-3 py-3`}>
                    <div className="d-flex justify-content-between pe-md-5">
                        <p className={checkStyles.total_order}>total order <span>in</span> cart</p>
                        <p className={`${checkStyles.total_items} pe-4 pe-md-5 me-md-5`}>3 items</p>
                    </div>
                    <div className="horizontal_line mt-2 mb-4" />
                    <div className="d-flex justify-content-between pe-md-5">
                        <p className={`${checkStyles.total_order} mb-0`}>total price</p>
                        <p className={`${checkStyles.total_price} pe-md-4 me-md-5 mb-0`}>150,000 egp</p>
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row my-4">
                    <div className={`${checkStyles.gray_container} d-flex col justify-content-between align-items-center me-md-4 px-4 py-3 mb-4 mb-md-0`}>
                        <div className="d-flex align-items-center">
                            <div className={checkStyles.img_box}>
                                <img src={bank} alt="bank logo" className="img" />
                            </div>
                            <div className="ms-2">
                                <p className={`${checkStyles.stor_title} mb-1`}>store securely</p>
                                <p className={`${checkStyles.stor_txt} mb-0`}>annual storage cost</p>
                            </div>
                        </div>
                        <p className={`${checkStyles.total_price} mb-0`}>7,300 egp</p>
                    </div>
                    <div className={`${checkStyles.gray_container} d-flex col justify-content-between align-items-center px-4 py-3`}>
                        <div className="d-flex align-items-center">
                            <div className={checkStyles.img_box}>
                                <img src={shipCar} alt="big-car logo" className="img" />
                            </div>
                            <div className="ms-2">
                                <p className={`${checkStyles.stor_title} mb-1`}>ship <span>to</span> my address</p>
                                <p className={`${checkStyles.stor_txt} mb-0`}>select your shipping address </p>
                            </div>
                        </div>
                        <div className={checkStyles.plus_img_box}>
                            <img src={addBtn} alt="add logo" className="img" />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row">
                    <div className={`${checkStyles.gray_container} col justify-content-between me-md-4 flex-grow-1 px-4 py-3 mb-4 mb-md-0`}>
                        <p className={`${checkStyles.checkout_title} mb-3`}>payment</p>
                        <p className={`${checkStyles.payment_txt}`}>Please select your payment method</p>
                        <div className="horizontal_line" />
                        <div className="mt-5 mb-3">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <div>
                                    <p className={`${checkStyles.payment_title} mb-1`}>instalments</p>
                                    <p className={`${checkStyles.payment_txt} mb-0`}>Pay with Installments</p>
                                </div>
                                <div className={checkStyles.plus_img_box}>
                                    <img src={valu} alt="valu logo" className="img" />
                                </div>
                                <input type="radio" id="instalments" className={checkStyles.radio_input} name="method" value='instalments' checked={selectedPayment === 'instalments'} onChange={(e) => setSelectedPayment(e.target.value)} />
                                <label htmlFor="instalments" className={checkStyles.radio_btn} />
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <div>
                                    <p className={`${checkStyles.payment_title} mb-1`}>credit/debit card</p>
                                    <p className={`${checkStyles.payment_txt} mb-0`}>Fees Apply</p>
                                </div>
                                <div className="d-flex">
                                    <img src={visa} alt="visa logo" className="me-2" />
                                    <img src={mastercard} alt="mastercard logo" className="me-2" />
                                    <img src={meeza} alt="meeza logo" />
                                </div>
                                <input type="radio" id="credit_debit" className={checkStyles.radio_input} name="method" value='credit/debit' checked={selectedPayment === 'credit/debit'} onChange={(e) => setSelectedPayment(e.target.value)} />
                                <label htmlFor="credit_debit" className={checkStyles.radio_btn} />
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <div>
                                    <p className={`${checkStyles.payment_title} mb-1`}>wallet</p>
                                    <p className={`${checkStyles.payment_txt} mb-0`}>Pay with your Wallet</p>
                                </div>
                                <div className={checkStyles.plus_img_box}>
                                    <img src={wallet} alt="wallet logo" className="img" />
                                </div>
                                <input type="radio" id="wallet" className={checkStyles.radio_input} name="method" value='wallet' checked={selectedPayment === 'wallet'} onChange={(e) => setSelectedPayment(e.target.value)} />
                                <label htmlFor="wallet" className={checkStyles.radio_btn} />
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <div>
                                    <p className={`${checkStyles.payment_title} mb-1`}>bank transfer</p>
                                    <p className={`${checkStyles.payment_txt} mb-0`}>Pay with Bank Account</p>
                                </div>
                                <div className={checkStyles.plus_img_box}>
                                    <img src={accountBalance} alt="bank account logo" className="img" />
                                </div>
                                <input type="radio" id="bank_transfer" className={checkStyles.radio_input} name="method" value='bank-transfer' checked={selectedPayment === 'bank-transfer'} onChange={(e) => setSelectedPayment(e.target.value)} />
                                <label htmlFor="bank_transfer" className={checkStyles.radio_btn} />
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <p className={`${checkStyles.payment_title} mb-1`}>fawry</p>
                                    <p className={`${checkStyles.payment_txt} mb-0`}>Pay with Fawry</p>
                                </div>
                                <div className={checkStyles.fawry_img_box}>
                                    <img src={fawry} alt="fawry logo" className="img" />
                                </div>
                                <input type="radio" id="fawry" className={checkStyles.radio_input} name="method" value='fawry' checked={selectedPayment === 'fawry'} onChange={(e) => setSelectedPayment(e.target.value)} />
                                <label htmlFor="fawry" className={checkStyles.radio_btn} />
                            </div>
                        </div>
                    </div>
                    <div className={`${checkStyles.gray_container} col justify-content-between flex-grow-1 px-4 py-3`}>
                        <p className={`${checkStyles.checkout_title} mb-5`}>order summary</p>
                        <div className="horizontal_line" />
                        <div className="me-md-5 my-5">
                            <div className="d-flex justify-content-between">
                                <p className={`${checkStyles.summary_title}`}>shipping</p>
                                <p className={`${checkStyles.summary_price}`}>360 egp</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className={`${checkStyles.summary_title}`}>subtotal</p>
                                <p className={`${checkStyles.summary_price}`}>24,740 egp</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className={`${checkStyles.summary_title}`}>discount 10%</p>
                                <p className={`${checkStyles.summary_price}`}>-400 egp</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className={`${checkStyles.summary_title}`}>total</p>
                                <p className={`${checkStyles.summary_price}`}>24,740 egp</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <Button title={'place order'} to={'/orderConfirm'} style={`w-50 py-1`} />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Checkout;
