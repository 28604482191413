import React from "react";
import trendStyles from './styles.module.css';

function TrendItem({ item }) {
    return (
        <div key={item.id} className={`${trendStyles.trend} col-4 col-sm-5 col-md-2 flex-grow-1 d-flex flex-column align-items-center py-4`}>
            <div className={trendStyles.trend_img_box}>
                <img src={item.url} alt='trejars' className='img' />
            </div>
            <p className={trendStyles.trend_title}>{item.title}</p>
            <p className={trendStyles.trend_txt}>{item.products}</p>
        </div>
    );
}

export default TrendItem;
