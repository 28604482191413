import React from "react";
import privacyStyles from './styles.module.css'

const PrivacyPolicy = () => {
    return (
        <main>
            <section className="my-5">
                <div className="container">
                    <>
                        <div className='d-flex justify-content-center'>
                            <h3 className={`${privacyStyles.privacy_title} mb-4`}>privacy policy</h3>
                        </div>
                        <p className={privacyStyles.privacy_txt}>
                            This Privacy Policy sets out the policy of Trejars with respect to the way we obtain, use, and disclose information about you through website
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            We understand and appreciate that you are concerned about privacy, particularly in relation to the use and disclosure of personal information. We are committed to providing a high level of privacy in relation to all personal information that is collected by us. The purpose of this Privacy Policy is to tell you what kind of information we may gather about you when you visit our site, how we may use that information, whether we disclose it to anyone, and the choices you have regarding how we will use your personal information, and your ability to correct this information. Our website allows you to choose what kind and how much information you provide to us and to control how we use whatever information you give us. Our goal is to provide you with a satisfying experience while allowing you to control your privacy and to give you the means to voice any questions or concerns you may have
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-4 mb-1`}>YOUR CONSENT</p>
                        <p className={privacyStyles.privacy_txt}>
                            You consent to your personal information being used in accordance with the privacy policy by visiting our website, by entering a competition on our website, by purchasing our products on the website and/or by providing us with your personal information on the website.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-4 mb-1`}>COLLECTION OF INFORMATION</p>
                        <p className={privacyStyles.privacy_txt}>
                            Information is collected from you primarily to make it easier and more rewarding for you to use our website and services. Depending on the service you are accessing, you could be asked to provide information such as your name, email address or information about what you like and do not like. It is entirely your choice whether to respond to these questions or not.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-4 mb-1`}>USE OF PERSONAL INFORMATION</p>
                        <p className={privacyStyles.privacy_txt}>
                            Trejars will use the personal information you have chosen to provide us for the purpose for which you provided it. Trejars will not use it for any other purpose without your consent. We might on occasions, use this information to notify you of any important changes to our site or any special promotions that may be of interest to you. On each email or communication that we send you, we will include simple instructions on how you can immediately unsubscribe from our mailing list. You can opt out from receiving such material at any time by emailing our privacy manager and asking to be removed from the mailing list. Any information we may collect from you will be treated with strict confidentiality.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-4 mb-1`}>DISCLOSURE</p>
                        <p className={privacyStyles.privacy_txt}>
                            There will be occasions where it will be necessary for Trejars to disclose your personal information to third parties. Trejars may be required to disclose your personal information to third parties to provide the products or services you have requested, for example, if you purchase products online, Trejars will need to disclose your personal information to third parties to bill and deliver your products. However, the disclosure will only be made where it is necessary to fulfill the purpose for which you disclosed your personal information. Otherwise, then stated above, we do not disclose personal information that you may give us, such as your name, address, email address or telephone number, to any organization or person outside BOUNCE, unless you have authorized us to do so, or unless we are requested to do so by a law enforcement agency in relation to a suspected breach of any law.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-4 mb-1`}>PASSWORDS</p>
                        <p className={privacyStyles.privacy_txt}>
                            Ultimately, you are solely responsible for maintaining the secrecy of your passwords and/or any personal information. Be responsible whenever you are online
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            Credit Cards We do not collect or store credit card information on our database or server. This information is managed through a secure credit card payment facility, all information sent to the processing bank is managed with the latest security software, systems, and processes (SSL).
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-4 mb-1`}>SECURITY</p>
                        <p className={privacyStyles.privacy_txt}>
                            Trejars operates secure data networks that are designed to protect your privacy and security. Trejars has a Security Policy that explains how we protect your privacy and personal information.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            Please note that our website does not provide systems for secure transmission of personal information across the internet, except where otherwise specifically stated. You should be aware that there are inherent risks in transmitting personal information via the internet and that we accept no responsibility for personal information provided via unsecured websites.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            We will not be held liable for loss or damage arising from unauthorized access to your personal information or a breach of this policy whether such access or breach was caused by our negligent or willful act or omission or that of our employees or agents.
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-4 mb-1`}>ACCESS AND CORRECTION</p>
                        <p className={privacyStyles.privacy_txt}>
                            The security of your information is important to us. The information we have collected about you cannot be seen or modified by anyone else. We make all reasonable efforts to ensure that information is stored securely both in electronic and physical form.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            You may access your information at any time. If you would like to know what information we hold about you, please contact us. If you discover that there is an error or information is missing, please notify us and we will try to correct or update the information as soon as possible.
                        </p>
                        <p className={privacyStyles.privacy_txt}>
                            We will try to ensure that all information we collect, use, or disclose about you is accurate, complete and up to date.
                        </p>
                    </>
                    <>
                        <div className='d-flex justify-content-center'>
                            <h3 className={`${privacyStyles.privacy_title} mt-5 mb-4`}>سياسة الخصوصية</h3>
                        </div>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            سياسة الخصوصية لدينا هي طريقة جمعنا وحفظنا واستخدامنا وطريقة حماية معلوماتك الشخصية ونعني بمعلومات الشخصية هي المعلومات التي ترتبط بشخص معين والتي تستخدم في التعريف عنه
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            نقوم بجمع المعلومات الشخصية من الراغبين بإستخدام موقع جراندي هوم او صفحات الانترنت المرتبطة. وبمجرد تزويدك لنا بمعلوماتك الشخصية تكون قد فوضتنا لمعالجة هذه المعلومات وفق بنود وشروط (سياسة الخصوصية)
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            قد نقوم بتعديل (سياسة الخصوصية) في اي وقت وذلك من خلال الإعلان عن هذا التعديل بنشر النسخة المعدلة على الموقع. وتكون النسخة المعدلة من (سياسة الخصوصية) سارية المفعول اعتبارا من تاريخ نشرها. في اعقاب النشر يعتبر استمرارك في استخدام الموقع موافقة منك بالشروط والبنود الواردة في النسخة المعدلة لطريقة معالجتنا وتعاملنا مع معلوماتك الشخصية التي كنت قد زودتنا بها
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            نأمل منكم الإطلاع على الموقع من حين لأخر للإعلانات عن اية تعديلات على سياسة الخصوصية
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-5 mb-1 text-end`}>جمعنا لمعلوماتك الشخصية</p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            كجزء من التسجيل على الموقع أو استخدامه سوف يطب منك تزويدنا بمعلومات شخصية محددة مثل اسمك وعنوان الشحن وبريدك الالكتروني و رقم هاتفك ومعلومات اخرى مشابهة وبعض المعلومات الاضافية عنك مثل تاريخ ميلادك أو اي معلومات محددة لهويتك. إضافة إلى ذلك وبهدف توثيقنا لهويتك
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            قد نحتاج ايضا الى جمع معلومات مالية محددة منك، مثلا بطاقتك الائتمانية و/أو تفاصيل حسابك المصرفي
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            يجب عليك ادخال هذه المعلومات المالية في قسم "حسابي" أو عند اتمام الاوردر على الموقع. وسوف نستخدم هذه المعلومات لغايات اصدار الفوانير واسنكمال طلباتك. عند تسجيلك في الموقع، ينبغى عليك عدم نشر اي معلومات شخصية (بما في ذلك اي معلومات مالية) على اي جزء من الموقع باستثناء جزء" إدارة الحساب" أو صفحة اتمام الاوردر وهذا من شأنه حمايتك من امكانية تعرضك للاحتيال او سرقة معلومات هويتك
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            سوف يتم جمع معلومات عن عملياتك وأنشطتك على الموقع.
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            رقم فريد يتم تعيينه لخادم الكمبيوتر أو مزود خدمة الإنترنت الخاص بك أو(ISP) لتحليل انشطتك وتحسين إدارة الموقع.
                            قد نجمع معلومات اضافية حول جهاز الكمبيوتر الخاص بك (على سبيل المثال نوع المتصفح) ومعلومات التصفح (على سبيل المثال الصفحات التي تزورها على الموقع) بالإضافة إلى عدد المرات التي تستخدم بها الموقع
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            أخيرًا، قد نجمع معلومات إضافية منك أو عنك بطرق أخرى غير موصوفة هنا تحديدًا. على سبيل المثال، قد نقوم بجمع المعلومات المتعلقة بتواصلك مع فريق دعم العملاء أو من خلال اجابتك على الاستبيانات. قد نجمع أيضًا التقييمات والتعليقات الأخرى المتعلقة باستخدامك للموقع. عندما نقوم بتجميع المعلومات الشخصية للأغراض الإحصائية، يجب أن تكون أسماء أصحاب المعلومات مجهولة
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-5 mb-1 text-end`}>استخدامنا لمعلوماتك الشخصية</p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            نستخدم معلوماتك الشخصية لتقديم خدمات ودعم من فريق العملاء وبهدف قياس مستوى خدماتنا وتحسينها لك ومنع النشاطات غير القانونية وتنفيذ لبنود اتفاقية الاستخدام الموفقة معك ("اتفاقية الاستخدام")، اضافة الى حل المشاكل وجمع الرسوم وتزويدك برسائل الكترونية ترويجية وكذلك من اجل توثيق المعلومات التي زودتنا بها مع اطراف ثالثة مثلا قد نلجأ الى مشاركة بعض من معلوماتك الشخصية مع البنوك او التفويض لبطاقات الائتمان لمعالجة وتوثيق خدمات أو مع اطراف ثالثة لغايات التحقق من عمليات الاحتيال <br />
                            برغم حرصنا للمحافظة على سرّيتك، الا اننا قد نحتاج الى الافصاح عن معلوماتك الشخصية لاجهزة تنفيذ القانون والهيئات الحكومية او اطراف ثالثة، نكون ملزمين بفعل ذلك باوامر من المحكمة اوغيرها من الدوائر القانونية لنكون ملتزمين ببنود القانون أو عند اعتقادنا ان الافصاح عن معلوماتك الشخصية ربما يقي من أذى جسدي او خسارة مالية او للاخبار عن نشاط مشبوه او للتحقيق في امكانية انتهاك لبنود وشروط اتفاقية المستخدم واية اتفاقيات أو لحماية الحقوق أو ملكية أو سلامة أومستخدمينا أو الغير <br />
                            سيتم عرض المعلومات المتعلقة بالمنتجات التي تقوم بشرائها على الموقع. يمكن أن تتضمن هذه المعلومات تفاصيل حول هوية المستخدم الخاصة بك والتقييمات والتعليقات المرتبطة باستخدامك للموقع. <br />
                            لا نبيع أو نؤجر اي من معلوماتك الشخصية لطرف ثالث ضمن نطاق عملنا التجاري المعتاد وسوف نشارك فقط معلوماتك الشخصية فقط وفق ما جاء في (سياسة الخصوصية) هذا <br />
                            بمجرد تسجيلك في أو استخدامك للموقع تعتبر أنك أعطيتنا تفويضا واضحا لتسلمك رسائل إلكترونية ترويجية حول خدماتنا إضافة الى رسائل إلكترونية بخصوص التغيرات والملامح الجديدة على الموقع ، بحال عدم رغبتك في تلقي مثل هذه الرسائل ,ماعليك سوى إختيار الرابط الخاص بإيقاف إرسال هذه الرسائل إلى عنوانك البريدي والمتوفر في اسفل اي من هذه الرسائل الإلكترونية <br />
                            بالاضافة الى ذلك نقوم باستخدام ملاحظاتك حول الموقع لغايات التسويق ومن خلال تقديمك لهذه الملاحظات تكون وافقت على أن نستخدم هذه الملاحظات لغايات التسويق <br />
                            سيتم الافصاح عن بعض البيانات الشخصية المتعلقة بك مع شركات الشحن مثل الاسم، العنوان، رقم الهاتف
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-5 mb-1 text-end`}>إستخدام ودخول وتصفح وتعديل معلوماتك الشخصية</p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            باستطاعتك الوصول ومراجعة معلوماتك الشخصية من خلال صفحة إدارة "حسابي"على الموقع، فاذا تغيرت معلوماتك الشخصية باي طريقة او اعطيت معلومات غير صحيحة على الموقع فعليك القيام بتحديثها او تصحيحها حالا، اما من خلال عرضها على "حسابي" او من خلال الاتصال بفريق خدمة العملاء. موضح على الموقع رقم التليفون والبريد الاكتروني الخاصين بخدمة العملاء
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            يرجى العلم أننا سوف نحتفظ بمعلوماتك الشخصية خلال وبعد انتهائك من استخدام الموقع بحسب ما هو مطلوب قانونا، وذلك بهدف التغلب على العوائق التقنية ,ولمنع الاحتيال، و للمساعدة في اي تحقيق قانوني ولاتخاذ اي اجراءات اخرى ينص عليها القانون
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-5 mb-1 text-end`}> (cookies)  ملفات تعريف الارتباط</p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            مثل العديد من مواقع الانترنت فان موقعنا يستخدم ملفات تعريف الإرتباط وهي ملفات حاسوب صغيرة يتم تثبيتها على الجزء الصلب من حاسوبك، فحين تذهب لزيارة صفحات انترنت معينة على الموقع, تتعرف السجل على المتصفح الخاص بك من خلال رقم فريد وعشوائي
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            كما ان ملفات تعريف الإرتباط التي نستخدمها لا تكشف اي من معلوماتك الشخصية.ان ملفات تعريف الإرتباط هذه تساعدنا في تحسين ادائك على الموقع وتعيننا على تفهم اي اجزاء من الموقع هي الاكثر استخداما,انت لك مطلق الحرية في الغاء هذه ملفات تعريف الإرتباط اذا أتيح لك ذلك من المتصفح ولا يؤثر ذلك على استخدامك للموقع
                        </p>
                        <p className={`${privacyStyles.privacy_sub_title} mt-5 mb-1 text-end`}>حماية معلوماتك الشخصية</p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            عندما تزودنا بمعلوماتك الشخصية نتخذ كافة الاحتياطات للمحافظة على معلوماتك وذلك بعدم الوصول اليها او استخدامها او الافصاح عنها بشكل غير مخول
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            كافة المعلومات الشخصية مشفرة، وعلى اي حال فان الانترنت وسيلة غير امنة ونحن لا نضمن سرية معلوماتك الشخصية
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            يجب ان يتم ادخال اسم المستخدام وكلمة السر في كل مرة عند الدخول الى حسابك أو القيام باية معامللات في الموقع
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            يجب ان يتم اختيار كلمة السر بحذر باستخدام ارقام وحروف واشكال مميزة
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            لا تشارك اسم المستخدم وكلمة السر مع اي احد
                        </p>
                        <p className={`${privacyStyles.privacy_txt} text-end`}>
                            في حال كان لديك شك حول اختراق اسم المستخدم وكلمة السر، يرجى على الفور الاتصال بفريق خدمة العملاء والعمل على تغيير كلمة السر وذلك بالدخول الى قسم حسابي على الموقع
                        </p>
                    </>
                </div>
            </section>
        </main>
    );
};

export default PrivacyPolicy;
