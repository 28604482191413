import React, { useEffect, useState } from "react";
import dashStyles from '../Dashboard/styles.module.css'
import infoStyles from './styles.module.css'
import { Button, InputField } from '../../components'
import { uploadIcon } from "../../assets";

function PersonalInfo({ pageID, activePageID }) {
  const [isActive, setIsActive] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setUploadedImage(imageUrl);
    }
  };

  useEffect(() => {
    if (activePageID === pageID) {
      setIsActive(true)
    } else {
      setIsActive(false);
    }
  }, [activePageID]);

  const COUNTRIES = ['Egypt', 'Algeria', 'Morocco', 'Sudan']

  return (
    <div className={`${isActive ? dashStyles.active_container : dashStyles.in_active_container} col-11 col-md-8 flex-md-grow-1 row`}>
      <div className="gray_box col-12 col-lg-7 me-lg-3 flex-grow-1 order-last order-lg-first py-4 pb-lg-5">
        <div className="d-flex justify-content-between mb-3">
          <InputField type={'text'} style={`${infoStyles.half_input}`} inputStyle={`px-2 py-2`} label={'first name*'} placeholder={'Enter your first name'} />
          <InputField type={'text'} style={`${infoStyles.half_input}`} inputStyle={`px-2 py-2`} label={'last name*'} placeholder={'Enter your last name'} />
        </div>
        <div className="d-flex justify-content-between mb-3">
          <InputField type={'email'} style={`${infoStyles.half_input}`} inputStyle={`px-2 py-2`} label={'email*'} placeholder={'Enter your Email'} />
          <InputField type={'tel'} style={`${infoStyles.half_input}`} inputStyle={`px-2 py-2`} label={'phone number*'} placeholder={'Enter your phone number'} />
        </div>
        <InputField list={COUNTRIES} isList={true} style={`mb-3`} inputStyle={`px-2 py-2`} label={'country*'} placeholder={'Select your Country'} />
        <InputField type={'text'} style={`mb-3`} inputStyle={`px-2 py-2`} label={'address*'} placeholder={'Enter your Address'} />
        <div className="d-flex justify-content-between mb-3">
          <InputField type={'text'} style={`${infoStyles.half_input}`} inputStyle={`px-2 py-2`} label={'city*'} placeholder={'Enter your City'} />
          <InputField type={'tel'} style={`${infoStyles.half_input}`} inputStyle={`px-2 py-2`} label={'postcode*'} placeholder={'Enter your Postcode'} />
        </div>
        <div className="d-flex align-items-center mt-4 justify-content-center justify-content-md-start">
          <p className={`${infoStyles.cancel} mb-0 ms-2`}>cancel</p>
          <Button title={'update profile'} style={`ms-5 px-4 py-2`} />
        </div>
      </div>
      <div className="gray_box col-12 col-lg-3 flex-grow-1 order-first order-lg-last py-4 mb-3 mb-lg-0">
        <div className="fit_box mb-3 mb-md-4 d-lg-none">
          <p className='dash_title mb-3'>your personal information</p>
          <div className="horizontal_line" />
        </div>
        <div className="d-flex flex-column align-items-center">
          <p className={`${infoStyles.update_title} mb-3`}>update your photo</p>
          <p className={`${infoStyles.update_size} mb-3`}>Size 200 X 200 px</p>
          <div className={infoStyles.img_box}>
            <label htmlFor="fileInput" className={infoStyles.upload_icon}>
              <img src={uploadIcon} alt="upload icon" className='img' />
            </label>
            <input
              id="fileInput"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
            />
            {uploadedImage ? (
              <img src={uploadedImage} alt="profile img" className={infoStyles.img} />
            ) : (<div className={infoStyles.img} />)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;
