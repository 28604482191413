import React, { useState } from "react";
import productStyles from './styles.module.css';
import { close, coin, minus, plus, trash } from "../../assets";

function CartProduct({ product, onRemove }) {
    const [count, setCount] = useState(1);

    return (
        <>
            <div className={`${productStyles.product_box} d-none d-md-flex row align-items-center mb-4`}>
                <div className={`${productStyles.item_img_box} col-md-2`}>
                    <img src={coin} alt='coin' className='img' />
                </div>
                <div className="col-md-3">
                    <p className={`${productStyles.product_cat} mb-2`}>gold coin</p>
                    <p className={`${productStyles.product_name} mb-2`}>21K Gold Pound from BTC</p>
                    <p className={productStyles.remove}>remove</p>
                </div>
                <div className={`${productStyles.counter_box} d-flex justify-content-around col-md-3 pe-4`}>
                    <div className={productStyles.trash_box} onClick={count > 1 ? () => setCount(count - 1) : onRemove}>
                        <img src={count > 1 ? minus : trash} alt='trash' className='img' />
                    </div>
                    <div className={productStyles.num_box}>
                        <p className={productStyles.num}>{count}</p>
                    </div>
                    <div className={productStyles.plus_box} onClick={() => setCount(count + 1)}>
                        <img src={plus} alt='plus' className='img' />
                    </div>
                </div>
                <p className={`${productStyles.product_price} col-md-2`}>24,740 egp</p>
                <p className={`${productStyles.product_price} col-md-2`}>24,740 egp</p>
            </div>

            <div className={`${productStyles.product_box} d-md-none row align-items-center mb-3 py-4 px-3`}>
                <div className={`${productStyles.item_img_box} col-5 me-3 flex-grow-1`}>
                    <img src={coin} alt='coin' className='img' />
                </div>
                <div className="col-6 d-flex flex-column">
                    <div className="d-flex justify-content-between mb-1">
                        <p className={`${productStyles.product_cat} px-1`}>gold coin</p>
                        <div className={productStyles.remove_icon_box} onClick={onRemove}>
                            <img src={close} alt="close" className="img" />
                        </div>
                    </div>
                    <p className={`${productStyles.product_name}`}>21K Gold Pound from BTC</p>
                    <p className={`${productStyles.price_now} mb-0`}>price now</p>
                    <p className={`${productStyles.product_price}`}>24,740 egp</p>
                    <div className={`${productStyles.counter_box} d-flex mt-2`}>
                        <div className={productStyles.trash_box} onClick={() => count > 1 ? setCount(count - 1) : setCount(1)}>
                            <img src={minus} alt='minus' className='img' />
                        </div>
                        <div className={productStyles.num_box}>
                            <p className={productStyles.num}>{count}</p>
                        </div>
                        <div className={productStyles.plus_box} onClick={() => setCount(count + 1)}>
                            <img src={plus} alt='plus' className='img' />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default CartProduct;
