export const handleWheel = (event, refName) => {
    if (!event) {
        throw new Error(`The handleWheel function argument (event) is required`);
    }
    if (!refName) {
        throw new Error(`The handleWheel function argument (refName) is required`);
    }
    event.preventDefault();
    const delta = Math.max(-1, Math.min(1, (event.nativeEvent.wheelDelta || -event.nativeEvent.detail)));
    refName.current.scrollLeft -= (delta * 50);
};

export const scroll = (scrollOffset, refName) => {
    if (!scrollOffset) {
        throw new Error(`The scroll function argument (scrollOffset) is required`);
    }
    if (!refName) {
        throw new Error(`The scroll function argument (refName) is required`);
    }

    const scrollDuration = 250;
    let start = null;
    const animateScroll = (timestamp) => {
        if (!start) start = timestamp;
        const progress = timestamp - start;
        const scrollProgress = progress / scrollDuration;
        if (scrollProgress < 1) {
            refName.current.scrollLeft += scrollOffset * scrollProgress;
            window.requestAnimationFrame(animateScroll);
        } else {
            refName.current.scrollLeft += scrollOffset;
        }
    };

    window.requestAnimationFrame(animateScroll);
};