import React from "react";
import privacyStyles from '../PrivacyPolicy/styles.module.css'

const RefundPolicy = () => {
    return (
        <main>
            <section className="my-5">
                <div className="container">
                    <div className='d-flex justify-content-center'>
                        <h3 className={privacyStyles.privacy_title}>Cancelation & refund policy</h3>
                    </div>
                    <p className={`${privacyStyles.privacy_sub_title} mb-0`}>1. GENERAL</p>
                    <p className={privacyStyles.privacy_txt}>
                        Trejars is committed to providing exceptional service in a timely manner Unfortunately,
                    </p>
                    <p className={privacyStyles.privacy_txt}>
                        when customer cancels without giving enough notice, it prevents another customer from being served. No shows and late cancellation have an impact on service quality while punishing customers who may show up earlier. For these reasons. Trejars has implemented a cancellation policy that will be strictly observed.
                    </p>
                    <p className={`${privacyStyles.privacy_sub_title} mb-0 mt-3`}>2. FULL PAYMENT</p>
                    <p className={privacyStyles.privacy_txt}>
                        Your registration is complete when we receive your full payment. Payments can be online or in person. We do not reserve Gold, Silver , precious metals or actions without payment.
                    </p>
                    <p className={privacyStyles.privacy_txt}>
                        An online confirmation email will be sent to you at the time of registration and payment. This email serves as confirmation of your registration.
                    </p>
                    <p className={`${privacyStyles.privacy_sub_title} mb-0 mt-3`}>3. CANCELLATION REQUEST</p>
                    <p className={privacyStyles.privacy_txt}>
                        Cancellation requests may be submitted by phone, email, online or in person before buying products. Please note that refunds will be processed in the original form of payment. If you have any questions or concerns about our cancellation policy, please contact us at +201027815611
                    </p>
                    <p className={privacyStyles.privacy_txt}>
                        Any cancellation requests will not be accepted if any customer already made the buy order.
                    </p>
                    <p className={`${privacyStyles.privacy_sub_title} mb-0 mt-3`}>4. REFUND POLICY</p>
                    <p className={privacyStyles.privacy_txt}>
                        As precious metals have live price changing we will refund according to the international live price of products ( Gold, Silver & precious metals ) not to the paid amount.
                    </p>
                </div>
            </section>
        </main>
    );
};

export default RefundPolicy;
