import React from "react";
import galleryStyles from './styles.module.css'

function GallaryItem({ item, isImg, styles }) {
    return (
        <div className={`${galleryStyles.GallaryItem} ${styles} d-flex flex-column justify-content-center align-items-center`}>
            {isImg ? (
                <img src={item.url} alt='trejars' className={`img cover`} />
            ) : (
                <>
                    <div className={galleryStyles.GallaryItem_icon_box}>
                        <img src={item.icon} alt='trejars' className="img" />
                    </div>
                    <p className={galleryStyles.gallery_title}>{item.title}</p>
                    <p className={galleryStyles.gallery_txt}>{item.text}</p>
                </>
            )}
        </div>
    );
}

export default GallaryItem;
