import { like1, like10, like11, like12, like13, like2, like3, like4, like5, like6, like7, like8, like9 } from "../../assets";

export const LIKESDATA = [
    { id: 1, url: like1, title: '21 karat gold necklace' },
    { id: 2, url: like2, title: '21 karat gold necklace' },
    { id: 3, url: like3, title: '21 karat gold necklace' },
    { id: 4, url: like4, title: '21 karat gold necklace' },
    { id: 5, url: like5, title: '21 karat gold necklace' },
    { id: 6, url: like6, title: '21 karat gold necklace' },
    { id: 7, url: like7, title: '21 karat gold necklace' },
    { id: 8, url: like8, title: '21 karat gold necklace' },
    { id: 8, url: like9, title: '21 karat gold necklace' },
    { id: 8, url: like10, title: '21 karat gold necklace' },
    { id: 8, url: like11, title: '21 karat gold necklace' },
    { id: 8, url: like12, title: '21 karat gold necklace' },
    { id: 8, url: like13, title: '21 karat gold necklace' },
]