import React from "react";
import confirmStyles from './styles.module.css';
import { confirmCircle } from '../../assets';
import { Button } from '../../components';

function OrderConfirm() {
    return (
        <main>
            <section>
                <div className="container">
                    <div className="d-flex flex-column align-items-center">
                        <div className={confirmStyles.img_box}>
                            <img src={confirmCircle} alt="confirm checked" className="img" />
                        </div>
                        <p className={confirmStyles.confirm_title}>order confirmed</p>
                        <p className={confirmStyles.confirm_txt}>thank you for your order</p>
                        <p className={`${confirmStyles.confirm_txt} px-md-5`}>You will receive a call from us to confirm your order. <br /> Additional you will receive email confirmation shortly</p>
                        <div className={`${confirmStyles.gray_box} p-3 col-12 col-md-6 col-lg-5`}>
                            <div className="d-flex justify-content-between pt-2 pb-4">
                                <p className={`${confirmStyles.order_title} mb-0`}>total order</p>
                                <p className={`${confirmStyles.order_txt} mb-0`}>3 items</p>
                            </div>
                            <div className="horizontal_line" />
                            <div className="d-flex justify-content-between py-4">
                                <p className={`${confirmStyles.order_title} mb-0`}>Product Id</p>
                                <p className={`${confirmStyles.order_txt} mb-0`}>#35426</p>
                            </div>
                            <div className="horizontal_line" />
                            <div className="d-flex justify-content-between py-4">
                                <p className={`${confirmStyles.order_title} mb-0`}>Payment Method</p>
                                <p className={`${confirmStyles.order_txt} mb-0`}>Credit card</p>
                            </div>
                            <div className="horizontal_line" />
                            <div className="d-flex justify-content-between py-4">
                                <p className={`${confirmStyles.order_title} mb-0`}>Status</p>
                                <p className={`${confirmStyles.order_txt} mb-0`}>Store Securely</p>
                            </div>
                            <div className="horizontal_line" />
                            <div className="d-flex justify-content-between py-4">
                                <p className={`${confirmStyles.order_title} mb-0`}>Total Amount</p>
                                <p className={`${confirmStyles.order_txt} mb-0`}>150,000EGP</p>
                            </div>
                            <div className="horizontal_line" />
                            <div className="d-flex flex-column align-items-center">
                                <Button title={'download inovace'} style={`py-2 d-block col-12 col-sm-8 col-lg-7 my-3`} />
                                <Button title={'continue shopping'} style={`${confirmStyles.secondry_btn} py-2 d-block col-12 col-sm-8 col-lg-7`} to={'/shop'} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default OrderConfirm;
