import React, { useEffect, useState } from "react";
import dashStyles from '../Dashboard/styles.module.css'
import PaymentStyles from './styles.module.css'
import { Button } from '../../components'
import { bankBlack, masterCard, visaCard } from "../../assets";

function PaymentMethod({ pageID, activePageID }) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (activePageID === pageID) {
      setIsActive(true)
    } else {
      setIsActive(false);
    }
  }, [activePageID]);

  return (
    <div className={`${isActive ? dashStyles.active_container : dashStyles.in_active_container} col-11 col-md-8 flex-md-grow-1 gray_box px-2 px-md-4 py-4 align-self-lg-stretch`}>
      <div className="fit_box mb-4 mb-md-5">
        <p className='dash_title mb-3'>payment method</p>
        <div className="horizontal_line" />
      </div>
      <div className="container-fluid">
        <div className="row align-items-center my-4">
          <div className="col-12 col-md-9 d-flex mb-3 mb-md-0">
            <div className={`${PaymentStyles.img_box} p-3`}>
              <img src={bankBlack} alt="bank logo" className="img" />
            </div>
            <div className="ms-5 ms-md-4">
              <p className={`${PaymentStyles.method_name} mb-2`}>National Bank of Egypt</p>
              <p className={`${PaymentStyles.method_number} mb-2`}>Bank **********5535</p>
              <p className={`${PaymentStyles.method_verify} mb-0`}>verified</p>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <Button title={'manage'} style={`py-2 w-100`} />
          </div>
        </div>
        <div className="horizontal_line d-block d-md-none" />
        <div className="row align-items-center my-4">
          <div className="col-12 col-md-9 d-flex mb-3 mb-md-0">
            <div className={`${PaymentStyles.img_box} p-3`}>
              <img src={masterCard} alt="masterCard logo" className="img" />
            </div>
            <div className="ms-5 ms-md-4">
              <p className={`${PaymentStyles.method_name} mb-2`}>Master Card</p>
              <p className={`${PaymentStyles.method_number} mb-2`}>Bank **********5535</p>
              <p className={`${PaymentStyles.method_verify} mb-0`}>verified</p>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <Button title={'manage'} style={`py-2 w-100`} />
          </div>
        </div>
        <div className="horizontal_line d-block d-md-none" />
        <div className="row align-items-center my-4">
          <div className="col-12 col-md-9 d-flex mb-3 mb-md-0">
            <div className={`${PaymentStyles.img_box} p-3`}>
              <img src={visaCard} alt="visaCard logo" className="img" />
            </div>
            <div className="ms-5 ms-md-4">
              <p className={`${PaymentStyles.method_name} mb-2`}>Visa Card</p>
              <p className={`${PaymentStyles.method_number} mb-2`}>Bank **********5535</p>
              <p className={`${PaymentStyles.method_verify} mb-0`}>verified</p>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <Button title={'manage'} style={`py-2 w-100`} />
          </div>
        </div>
        <div className="horizontal_line d-block d-md-none" />
      </div>
      <Button title={'add card'} style={`${PaymentStyles.secondry_btn} mt-3 px-5 py-3 py-md-2 me-3`} />
      <Button title={'add bank'} style={`mt-3 px-5 py-3 py-md-2`} />
    </div>
  );
}

export default PaymentMethod;
