import React from "react";
import { Link } from "react-router-dom";
import btnStyles from './styles.module.css'

function Button({ title, onClick, to, style }) {
    return (
        <>
            {to ? (
                <Link to={to} onClick={onClick} className={`${btnStyles.btn} ${style}`}>{title}</Link>
            ) : (
                <button onClick={onClick} className={`${btnStyles.btn} ${style}`}>{title}</button>
            )}
        </>
    );
}

export default Button;
