import React, { useEffect, useState } from "react";
import dashStyles from '../Dashboard/styles.module.css'
import addressStyles from './styles.module.css'
import { Button } from "../../components";

function Address({ pageID, activePageID }) {
  const [isActive, setIsActive] = useState(false);
  const [addresses, setAddresses] = useState([{}, {}]);

  useEffect(() => {
    if (activePageID === pageID) {
      setIsActive(true)
    } else {
      setIsActive(false);
    }
  }, [activePageID]);

  return (
    <div className={`${isActive ? dashStyles.active_container : dashStyles.in_active_container} col-11 col-md-8 flex-md-grow-1 gray_box px-2 px-lg-3 pt-4 pb-3 align-self-lg-stretch`}>
      <div className="fit_box">
        <p className='dash_title mb-3'>your address</p>
        <div className="horizontal_line" />
      </div>
      <div className="container-fluid mt-5">
        <div className={`${addressStyles.cards_box} row`}>
          {addresses.map(() => {
            return (
              <div className={`${addressStyles.address_box} col-12 col-md px-3 pt-4 pb-3`}>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <p className={addressStyles.address_name}>address 1</p>
                  <p className={addressStyles.edit_address}>edit</p>
                </div>
                <div className="row align-items-center justify-content-between mt-3">
                  <p className={`${addressStyles.address_key} col-3 col-lg-2 mb-0`}>name:</p>
                  <p className={`${addressStyles.address_value} col-9 col-lg-10 mb-0`}>mahmoud</p>
                </div>
                <div className="row align-items-center justify-content-between mt-3">
                  <p className={`${addressStyles.address_key} col-3 col-lg-2 mb-0`}>email:</p>
                  <p className={`${addressStyles.address_value} col-9 col-lg-10 mb-0`}>Example@Gmail.com</p>
                </div>
                <div className="row align-items-center justify-content-between mt-3">
                  <p className={`${addressStyles.address_key} col-3 col-lg-2 mb-0`}>phone:</p>
                  <p className={`${addressStyles.address_value} col-9 col-lg-10 mb-0`}>01145036524</p>
                </div>
                <div className="row align-items-center justify-content-between mt-3">
                  <p className={`${addressStyles.address_key} col-3 col-lg-2 mb-0`}>city:</p>
                  <p className={`${addressStyles.address_value} col-9 col-lg-10 mb-0`}>Cairo</p>
                </div>
                <div className="row align-items-center justify-content-between mt-3">
                  <p className={`${addressStyles.address_key} col-3 col-lg-2 mb-0`}>zip:</p>
                  <p className={`${addressStyles.address_value} col-9 col-lg-10 mb-0`}>568</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <Button title={'add new address'} style={`px-4 py-2 mt-4`} />
    </div>
  );
}

export default Address;
