import React, { useState } from "react";
import loginStyles from '../Login/styles.module.css';
import { apple, checkBox, checkedBox, eye, eyeSlash, facebookCircle, google, service3 } from "../../assets";
import { Button, InputField } from "../../components";

function SignUp() {
  const [isChecked, setIsChecked] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    idFront: null,
    idBack: null,
    addressImg: null,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileInputChange = (event) => {
    const { name } = event.target;
    const value = event.target.files[0];
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <main>
      <div className="container pt-5">
        <div className="row justify-content-center">
          <div className={`${loginStyles.login_content} col-11 col-md-7 col-lg-5 ps-4 pe-3 pb-3`}>
            <p className={loginStyles.login_txt}>sign up</p>
            <p className={loginStyles.welcome_txt}>TREJARS</p>
            <InputField
              type={'text'}
              fieldName={'email'}
              label={'email *'}
              placeholder={'Enter Your Email'}
              style={loginStyles.field_box}
              inputStyle={loginStyles.input}
              value={formData.email}
              onChange={handleInputChange}
            />
            <InputField
              type={!isPasswordVisible ? 'password' : 'text'}
              fieldName={'password'}
              label={'set password *'}
              placeholder={'Enter Your Password'}
              style={loginStyles.field_box}
              inputStyle={loginStyles.input}
              value={formData.password}
              onChange={handleInputChange}
              rightIcon={isPasswordVisible ? <img src={eye} alt="eye" className="img" /> : <img src={eyeSlash} alt="eye-slash" className="img" />}
              onRightIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
            />
            <InputField
              type={!isConfirmPasswordVisible ? 'password' : 'text'}
              fieldName={'password'}
              label={'confirm password *'}
              placeholder={'Enter confirm Password'}
              style={loginStyles.field_box}
              inputStyle={loginStyles.input}
              value={formData.confirmPassword}
              onChange={handleInputChange}
              rightIcon={isConfirmPasswordVisible ? <img src={eye} alt="eye" className="img" /> : <img src={eyeSlash} alt="eye-slash" className="img" />}
              onRightIconClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
            />
            <InputField
              type={'text'}
              fieldName={'phone'}
              label={'phone *'}
              placeholder={'Enter Your Phone'}
              style={loginStyles.field_box}
              inputStyle={loginStyles.input}
              value={formData.phone}
              onChange={handleInputChange}
            />
            <div className="d-flex align-items-center c-pointer mb-3" onClick={() => setIsChecked(!isChecked)}>
              {isChecked ? (
                <img src={checkedBox} alt="checked box" />
              ) : (
                <img src={checkBox} alt="check box" />
              )}
              <p className={`${loginStyles.rem_txt} mb-0 ms-2`}>Remember me</p>
            </div>
            <InputField
              type={'file'}
              fieldName={'idFront'}
              label={'national id front'}
              placeholder={'no file selected'}
              value={formData.idFront?.name}
              onChange={handleFileInputChange}
            />
            <InputField
              type={'file'}
              fieldName={'idBack'}
              label={'national id back'}
              placeholder={'no file selected'}
              value={formData.idBack?.name}
              onChange={handleFileInputChange}
            />
            <InputField
              type={'file'}
              fieldName={'addressImg'}
              label={'proof of address (utility bill)'}
              placeholder={'no file selected'}
              value={formData.addressImg?.name}
              onChange={handleFileInputChange}
            />
            <Button to='/home' title={'sign up'} style={loginStyles.login_btn} />
            <p className={loginStyles.login_with_txt}>or sign up with</p>
            <div className="d-flex justify-content-center mb-3">
              <div className={loginStyles.icon_box}>
                <img src={apple} alt="apple" className="img cover" />
              </div>
              <div className={loginStyles.icon_box}>
                <img src={google} alt="gmail" className="img cover" />
              </div>
              <div className={loginStyles.icon_box}>
                <img src={facebookCircle} alt="facebook" className="img cover" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default SignUp;
