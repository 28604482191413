import React from "react";
import bannerStyles from './styles.module.css';

function AppBanner({ title, url }) {
    return (
        <div className={bannerStyles.banner_box} style={{ backgroundImage: `url(${url})` }} >
            <h1 className={bannerStyles.banner_title}>
                {title}
            </h1>
        </div >
    );
}

export default AppBanner;
