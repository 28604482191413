import React from "react";
import privacyStyles from '../PrivacyPolicy/styles.module.css'

const DeliveryPolicy = () => {
    return (
        <main>
            <section className="my-5">
                <div className="container">
                    <div className='d-flex justify-content-center'>
                        <h3 className={privacyStyles.privacy_title}>delivery policy</h3>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <h3 className={`${privacyStyles.privacy_title} mt-1 mb-4`}>(1)</h3>
                    </div>
                    <p className={privacyStyles.privacy_txt}>
                        Please be advised that the official business days for Trejars from Sunday to Thursday. Fridays, Saturdays, holidays, and public holidays its holidays for all employees of Trejars and shipping companies. <br />
                        User can request a fast delivery option for more fees if available from our side. <br />
                        Our first mission is to offer you the best online shopping experience, so we have made it easier for you to make your shipment easier because your convenience is our priority <br />
                        Trejars mainly delivers your orders through a third party guarded delivery to ensure the timely & safely shipping of your order. <br />
                        The method of delivery at Trejars is to deliver within two to four days as a maximum, depending on your geographical area, giving you the option to choose the right time for you to deliver at any time during the day, week or even during the month. Some orders can take longer times according to the customer request for special designs. <br />
                        In the case of urgent orders, please contact our customer service and we will do our best to help you. <br />
                        We will ask for your signature on a copy of the invoice to confirm receipt of the goods. We will deliver the order to the registered address, and we consider the signature of anyone at the address as a receipt of the order. <br />
                        If there is no one at the registered address to receive your order, we will ask you to contact our customer service to arrange your visit to our store to collect your ordered product. The company is not able to attempt delivery more than one time. The company is not responsible for any order after delivery.
                    </p>
                    <div className='d-flex justify-content-center'>
                        <h3 className={`${privacyStyles.privacy_title} mt-5 mb-4`}>(2)</h3>
                    </div>
                    <p className={privacyStyles.privacy_txt}>
                        In-stock Orders are fulfilled within 2-7 business days. <br />
                        Custom-made Orders are fulfilled according to individual production time which is stated on the product description page.
                        <p className={`${privacyStyles.privacy_sub_title} mb-0`}>Delivery cost is either</p>
                        Calculated at check-out depending on the product and the delivery address <br />
                        Quoted after order for special orders, and included on your order’s final balance
                    </p>
                    <div className='d-flex justify-content-center'>
                        <h3 className={`${privacyStyles.privacy_title} mt-5 mb-4`}>(3)</h3>
                    </div>
                    <p className={`${privacyStyles.privacy_sub_title} mb-0`}>Delivery Time</p>
                    <p className={privacyStyles.privacy_txt}>
                        An estimated delivery time will be provided to you once your order is placed. Delivery times are estimates and commence from the date of shipping, rather than the date of order. Delivery times are to be used as a guide only and are subject to the acceptance and approval of your order. <br />
                        Unless there are exceptional circumstances, we make every effort to fulfill your order within 5 business days of the date of your order. Business days mean Saturday to Friday, except holidays. <br />
                        Date of delivery may vary due to carrier shipping practices, delivery location, method of delivery, and the items ordered. Products may also be delivered in separate shipments. <br />
                        To find out how much your order will cost, simple add the items you would like to purchase to your cart, and proceed to the checkout page. Once at the checkout screen, shipping charges will be displayed.
                    </p>
                </div>
            </section>
        </main>
    );
};

export default DeliveryPolicy;
