import React, { useState } from "react";
import footerStyles from './styles.module.css'
import { Link } from "react-router-dom";
import InputField from "../InputField";
import Button from "../Button";
import { facebook, instagram, linkedin, navLogo, twitter, youtube } from "../../assets";

function Footer() {
    const [subMail, setSubMail] = useState('')
    return (
        <footer className={footerStyles.footer} >
            <div className="container">
                <div className={"row justify-content-between g-5 mt-5"}>
                    <div className={"d-flex justify-content-between flex-grow-1 col-11 col-lg-6"}>
                        <div>
                            <p className={footerStyles.footer_section_title}>navigation</p>
                            <div className={footerStyles.footer_section_links}>
                                <Link className={footerStyles.footer_section_link} to={'home'}>Home</Link>
                                <Link className={footerStyles.footer_section_link} to={'aboutUs'}>About us</Link>
                                <Link className={footerStyles.footer_section_link} to={'/'}>My Account</Link>
                                <Link className={footerStyles.footer_section_link} to={'shop'}>Shop</Link>
                                <Link className={footerStyles.footer_section_link} to={'contactUs'}>Contact us</Link>
                            </div>
                        </div>
                        <div>
                            <p className={footerStyles.footer_section_title}>product</p>
                            <div className={footerStyles.footer_section_links}>
                                <Link className={footerStyles.footer_section_link} to={'/'}>Order History</Link>
                                <Link className={footerStyles.footer_section_link} to={'/'}>Wishlist</Link>
                                <Link className={footerStyles.footer_section_link} to={'cart'}>Cart</Link>
                                <Link className={footerStyles.footer_section_link} to={'/'}>Payment Method</Link>
                            </div>
                        </div>
                        <div>
                            <p className={footerStyles.footer_section_title}>company</p>
                            <div className={footerStyles.footer_section_links}>
                                <Link className={footerStyles.footer_section_link} to={'goldPrice'}>Gold price</Link>
                                <Link className={footerStyles.footer_section_link} to={'goldPriceHistory'}>Gold Price History</Link>
                                <Link className={footerStyles.footer_section_link} to={'/privacyPolicy'}>Privacy Policy</Link>
                                <Link className={footerStyles.footer_section_link} to={'/termsAndConditions'}>Terms & Conditions</Link>
                                <Link className={footerStyles.footer_section_link} to={'/refundPolicy'}>Refund Policy</Link>
                                <Link className={footerStyles.footer_section_link} to={'/deliveryPolicy'}>Delivery Policy</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-5">
                        <p className={footerStyles.footer_subscribe_title}>
                            sign up for our newsletter
                        </p>
                        <div className={`${footerStyles.footer_subscribe_box} d-flex mb-4`}>
                            <InputField placeholder={'Enter email'} value={subMail} onChange={(e) => setSubMail(e.target.value)} inputStyle={`${footerStyles.footer_subscribe_input} flex-grow-1`} />
                            <div>
                                <Button title={'subscribe'} onClick={() => setSubMail('')} style={`${footerStyles.footer_subscribe_btn}`} />
                            </div>
                        </div>
                        <p className={footerStyles.footer_section_link}>Tax registration number: 759-686-858 <br /> Ministry of Supply, Jewellery and Scales Stamping Service : 0200306</p>
                    </div>
                </div>
                <div className={footerStyles.horizontal_footer_line} />
                <div className="row justify-content-center justify-content-md-between align-items-center pb-4 gy-4">
                    <div className={`${footerStyles.contact_footer_icons} col-11 col-md d-flex justify-content-center`}>
                        <div>
                            <img src={linkedin} alt="linkedin" className="img" />
                        </div>
                        <div>
                            <img src={twitter} alt="twitter" className="img" />
                        </div>
                        <div>
                            <img src={instagram} alt="instagram" className="img" />
                        </div>
                        <div>
                            <img src={facebook} alt="facebook" className="img" />
                        </div>
                        <div>
                            <img src={youtube} alt="youtube" className="img" />
                        </div>
                    </div>
                    <figure className={`${footerStyles.footer_log_Box} col-11 col-md d-flex justify-content-center`}>
                        <img src={navLogo} alt="site-Logo" className='img' />
                    </figure>
                    <p className={`${footerStyles.copy_right} col-11 col-md d-flex justify-content-center`}>© 2024 Trejars. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
