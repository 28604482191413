import React, { useRef, useState } from "react";
import cartStyles from './styles.module.css';
import { arrowLeft } from "../../assets";
import { Button, CartProduct, MayLikeItem } from "../../components";
import { LIKESDATA } from "../../utils/data";
import { handleWheel } from "../../utils/functions";


function Cart() {
    const [products, setProducts] = useState([{}, {}, {}]);
    const likesRef = useRef(null);

    const removeProduct = (index) => {
        setProducts(products.filter((_, i) => i !== index))
    }

    return (
        <main>
            <section className="mt-4 mb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className={`${cartStyles.cart_box} p-3 col-11 col-md-7 me-md-3 flex-grow-1`}>
                            <div className={`${cartStyles.cart_title_box} d-flex justify-content-between align-items-baseline pb-md-4 ps-2 pe-4 pt-2 pt-md-0`}>
                                <p className={cartStyles.cart_title}>shopping cart</p>
                                <p className={cartStyles.items_num}>3 items</p>
                            </div>
                            <div className='horizontal_line d-none d-lg-block' />
                            <div className="container-fluid mt-3">
                                <div className="row align-items-baseline mb-2 d-none d-md-flex">
                                    <p className={`${cartStyles.shopping_cart_title} col-5`}>product details</p>
                                    <p className={`${cartStyles.shopping_cart_title} col-3`}>quantity</p>
                                    <p className={`${cartStyles.shopping_cart_title} col-2`}>price</p>
                                    <p className={`${cartStyles.shopping_cart_title} col-2`}>total</p>
                                </div>
                                <div className='horizontal_line d-none d-md-block d-lg-none mb-4' />
                                {products.map((item, index) => <CartProduct product={item} onRemove={() => removeProduct(index)} />)}
                                <div className={`${cartStyles.continue_box} d-none d-md-flex align-items-baseline`}>
                                    <div className={cartStyles.continue_arrow}>
                                        <img src={arrowLeft} alt='arrow left' className='img' />
                                    </div>
                                    <p className={cartStyles.continue_txt}>continue shopping</p>
                                </div>
                            </div>
                        </div>
                        <div className={`${cartStyles.order_box} py-3 col-11 col-md-4 px-3 px-md-0`}>
                            <div className={`${cartStyles.order_title_box} px-md-3 px-lg-0`}>
                                <p className={`${cartStyles.cart_title} pb-0 pb-lg-3 px-lg-3`}>order summary</p>
                                <div className='horizontal_line' />
                            </div>
                            <div className="d-flex justify-content-between px-md-3 mt-4 mt-lg-3">
                                <p className={cartStyles.items_title}>items 3</p>
                                <p className={cartStyles.items_price}>24,740 egp</p>
                            </div>
                            <div className="d-flex justify-content-between px-md-3 my-3">
                                <p className={cartStyles.shipping_title}>shipping</p>
                                <p className={cartStyles.items_price}>24,740 egp</p>
                            </div>
                            <div className='horizontal_line d-md-none mb-4' />
                            <p className={`${cartStyles.promo_title} mx-md-3`}>promo code</p>
                            <input type="text" className={`${cartStyles.promo_input} mx-md-3 mb-3 flex-grow-1`} />
                            <Button title={'apply'} style={`${cartStyles.promo_btn} mx-md-3 mb-3 mb-lg-5`} />
                            <div className='horizontal_line' />
                            <div className="d-flex justify-content-between px-md-3 mt-lg-5 mb-lg-4 mt-3 mb-1">
                                <p className={cartStyles.total}>total</p>
                                <p className={cartStyles.total_price}>24,740 egp</p>
                            </div>
                            <Button to={'/checkout'} title={'checkout'} style={`${cartStyles.checkout_btn} mx-md-3`} />
                        </div>
                    </div>
                </div>
            </section>
            {/* may_like */}
            <section className={cartStyles.may_like}>
                <div className="container">
                    <p className={cartStyles.may_title}>you may like</p>
                    <div className={`${cartStyles.likes_box} d-flex mt-0`} onWheel={(event) => handleWheel(event, likesRef)} ref={likesRef}>
                        {LIKESDATA.map((item) => <MayLikeItem item={item} />)}
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Cart;
