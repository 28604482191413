import React, { useEffect, useRef, useState } from "react";
import shopStyles from './styles.module.css'
import cartStyles from '../Cart/styles.module.css'
import { AppBanner, Button, MayLikeItem, ProductItem } from "../../components";
import { checkBox, checkedBox, close, contactBanner, down, filter } from "../../assets";
import { LIKESDATA } from "../../utils/data";
import { handleWheel } from "../../utils/functions";
import MultiRangeSlider from "multi-range-slider-react";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { GetAllProducts } from "../../redux/slices/productsSlice";

function Shop() {
  const likesRef = useRef(null);
  const [isType, setIsType] = useState({
    gold: false,
    silver: false
  });
  const [isKarat, setIsKarat] = useState({
    gold21: false,
    gold24: false
  });
  const [isCat, setIsCat] = useState({
    castBars: false,
    ingots: false,
    coins: false,
    silverBars: false,
    ovalIngots: false,
    wearables: false,
  });
  const [selectedWeightId, setSelectedWeightId] = useState(1);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(4250);
  const handleInput = (e) => {
    setMinValue(e.minValue);
    setMaxValue(e.maxValue);
  };
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: "767px" });

  useEffect(() => {
    if (!isMobile) {
      setIsFilterOpen(false)
    }
  }, [isMobile]);

  useEffect(() => {
    if (isFilterOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isFilterOpen]);

  const WEIGHTS = [
    { id: 1, value: '8.40gm' },
    { id: 2, value: '8.40gm' },
    { id: 3, value: '8.40gm' },
    { id: 4, value: '8.40gm' },
    { id: 5, value: '8.40gm' },
    { id: 6, value: '8.40gm' },
  ]

  const dispatch = useDispatch();
  const intervalId = useRef(null);

  useEffect(() => {
    dispatch(GetAllProducts());
    const reFetchData = () => {
      dispatch(GetAllProducts());
    };
    intervalId.current = setInterval(reFetchData, 30000);
    return () => clearInterval(intervalId.current);
  }, []);

  const { loading, allProducts } = useSelector(state => state.products);

  return (
    <main className="bg_dark">
      <div className="mt-lg-4 mb-5">
        <AppBanner url={contactBanner} title={'shop'} />
      </div>
      <div className="container">
        <div className="row mb-5">
          <div className={`${isFilterOpen ? shopStyles.filter_box : shopStyles.filter_box_closed} px-3 px-md-0 py-3 py-md-0 col-md-4 col-lg-3 me-md-2`}>
            {isFilterOpen && (
              <div className="d-flex justify-content-between align-items-baseline px-1">
                <div className="d-flex align-items-center mb-3">
                  <img src={filter} alt="filter icon" className={shopStyles.filter_icon} />
                  <p className={`${shopStyles.filter_txt_big} mb-0 ms-1`}>filter</p>
                </div>
                <img src={close} alt="close" className={shopStyles.filter_icon} onClick={() => setIsFilterOpen(false)} />
              </div>
            )}
            <div className="gray_box px-2 py-3 mt-3">
              <div className={shopStyles.fit_box}>
                <p className={`${shopStyles.filter_title} mb-1`}>Filter by type</p>
                <div className="horizontal_line mb-3" />
              </div>
              <div className="d-flex align-items-center justify-content-between pe-md-4 pe-xlg-5 mb-2">
                <div className="d-flex align-items-center">
                  <div onClick={() => setIsType({ ...isType, gold: !isType.gold })}>
                    {isType.gold ? (
                      <img src={checkedBox} alt="checked box" />
                    ) : (
                      <img src={checkBox} alt="check box" />
                    )}
                  </div>
                  <p className={`${shopStyles.checkbox_txt} ms-2 mb-0`}>gold</p>
                </div>
                <p className={`${shopStyles.type_items} mb-0`}>150 items</p>
              </div>
              <div className="d-flex align-items-center justify-content-between pe-md-4 pe-xlg-5">
                <div className="d-flex align-items-center">
                  <div onClick={() => setIsType({ ...isType, silver: !isType.silver })}>
                    {isType.silver ? (
                      <img src={checkedBox} alt="checked box" />
                    ) : (
                      <img src={checkBox} alt="check box" />
                    )}
                  </div>
                  <p className={`${shopStyles.checkbox_txt} ms-2 mb-0`}>silver</p>
                </div>
                <p className={`${shopStyles.type_items} mb-0`}>53 items</p>
              </div>
            </div>
            <div className="gray_box px-2 py-3 mt-3">
              <div className={shopStyles.fit_box}>
                <p className={`${shopStyles.filter_title} mb-1`}>Filter by Weight</p>
                <div className="horizontal_line mb-3" />
                <div className="container-fluid">
                  <div className="pe-4 row gap-3 column-gap-lg-2 column-gap-xl-3">
                    {WEIGHTS.map((item) => <p className={`${selectedWeightId === item.id ? shopStyles.selected_weight_value : shopStyles.weight_value} col-3 mb-0 c-pointer px-1 px-xl-2 py-1 flex-grow-1`} key={item.id} onClick={() => setSelectedWeightId(item.id)}>{item.value}</p>)}
                  </div>
                </div>
              </div>
            </div>
            <div className="gray_box px-2 py-3 mt-3">
              <div className={shopStyles.fit_box}>
                <p className={`${shopStyles.filter_title} mb-1`}>Filter by Price</p>
                <div className="horizontal_line mb-3" />
              </div>
              <MultiRangeSlider
                min={0}
                max={15000}
                step={5}
                minValue={minValue}
                maxValue={maxValue}
                onInput={(e) => {
                  handleInput(e);
                }}
                label={false}
                ruler={false}
                barLeftColor="#FCC65B80"
                barInnerColor="#FCC65B"
                barRightColor="#FCC65B80"
                thumbLeftColor="#FCC65B"
                thumbRightColor="#FCC65B"
                className={shopStyles.range_filter}
              />
            </div>
            <div className="gray_box px-2 py-3 mt-3">
              <div className={shopStyles.fit_box}>
                <p className={`${shopStyles.filter_title} mb-1`}>Filter by Karat</p>
                <div className="horizontal_line mb-3" />
              </div>
              <div className="d-flex align-items-center justify-content-between pe-md-4 pe-xlg-5 mb-2">
                <div className="d-flex align-items-center">
                  <div onClick={() => setIsKarat({ ...isKarat, gold21: !isKarat.gold21 })}>
                    {isKarat.gold21 ? (
                      <img src={checkedBox} alt="checked box" />
                    ) : (
                      <img src={checkBox} alt="check box" />
                    )}
                  </div>
                  <p className={`${shopStyles.checkbox_txt2} ms-2 mb-0 fw-medium`}>21k</p>
                </div>
                <p className={`${shopStyles.karat_items} mb-0`}>22 items</p>
              </div>
              <div className="d-flex align-items-center justify-content-between pe-md-4 pe-xlg-5 mb-2">
                <div className="d-flex align-items-center">
                  <div onClick={() => setIsKarat({ ...isKarat, gold24: !isKarat.gold24 })}>
                    {isKarat.gold24 ? (
                      <img src={checkedBox} alt="checked box" />
                    ) : (
                      <img src={checkBox} alt="check box" />
                    )}
                  </div>
                  <p className={`${shopStyles.checkbox_txt2} ms-2 mb-0 fw-medium`}>24k</p>
                </div>
                <p className={`${shopStyles.karat_items} mb-0`}>29 items</p>
              </div>
            </div>
            <div className="gray_box px-3 py-3 mt-3">
              <div className={shopStyles.fit_box}>
                <p className={`${shopStyles.filter_title} mb-1`}>Filter by Categories</p>
                <div className="horizontal_line mb-3" />
              </div>
              <div className="d-flex align-items-center mt-4">
                <div onClick={() => setIsCat({ ...isCat, castBars: !isCat.castBars })}>
                  {isCat.castBars ? (
                    <img src={checkedBox} alt="checked box" />
                  ) : (
                    <img src={checkBox} alt="check box" />
                  )}
                </div>
                <p className={`${shopStyles.checkbox_txt2} ms-2 mb-0`}>cast bars</p>
              </div>
              <div className="d-flex align-items-center mt-4">
                <div onClick={() => setIsCat({ ...isCat, ingots: !isCat.ingots })}>
                  {isCat.ingots ? (
                    <img src={checkedBox} alt="checked box" />
                  ) : (
                    <img src={checkBox} alt="check box" />
                  )}
                </div>
                <p className={`${shopStyles.checkbox_txt2} ms-2 mb-0`}>ingots</p>
              </div>
              <div className="d-flex align-items-center mt-4">
                <div onClick={() => setIsCat({ ...isCat, coins: !isCat.coins })}>
                  {isCat.coins ? (
                    <img src={checkedBox} alt="checked box" />
                  ) : (
                    <img src={checkBox} alt="check box" />
                  )}
                </div>
                <p className={`${shopStyles.checkbox_txt2} ms-2 mb-0`}>coins</p>
              </div>
              <div className="d-flex align-items-center mt-4">
                <div onClick={() => setIsCat({ ...isCat, silverBars: !isCat.silverBars })}>
                  {isCat.silverBars ? (
                    <img src={checkedBox} alt="checked box" />
                  ) : (
                    <img src={checkBox} alt="check box" />
                  )}
                </div>
                <p className={`${shopStyles.checkbox_txt2} ms-2 mb-0`}>silver bars</p>
              </div>
              <div className="d-flex align-items-center mt-4">
                <div onClick={() => setIsCat({ ...isCat, ovalIngots: !isCat.ovalIngots })}>
                  {isCat.ovalIngots ? (
                    <img src={checkedBox} alt="checked box" />
                  ) : (
                    <img src={checkBox} alt="check box" />
                  )}
                </div>
                <p className={`${shopStyles.checkbox_txt2} ms-2 mb-0`}>oval ingots</p>
              </div>
              <div className="d-flex align-items-center mt-4 mb-2">
                <div onClick={() => setIsCat({ ...isCat, wearables: !isCat.wearables })}>
                  {isCat.wearables ? (
                    <img src={checkedBox} alt="checked box" />
                  ) : (
                    <img src={checkBox} alt="check box" />
                  )}
                </div>
                <p className={`${shopStyles.checkbox_txt2} ms-2 mb-0`}>wearables</p>
              </div>
            </div>
            {isFilterOpen && (
              <Button title={'view products'} style={`w-100 py-2 mt-3`} />
            )}
          </div>
          <div className="col-12 col-md-7 col-lg-8 flex-md-grow-1">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <p className={`${shopStyles.all_txt} mb-0`}>showing all {allProducts.length} results</p>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center me-3 d-md-none" onClick={() => setIsFilterOpen(true)}>
                  <img src={filter} alt="filter icon" className={shopStyles.filter_icon} />
                  <p className={`${shopStyles.filter_txt} mb-0 ms-1`}>filter</p>
                </div>
                <div className="d-flex align-items-baseline c-pointer">
                  <p className={`${shopStyles.filter_txt} mb-0 me-2`}>sort by</p>
                  <img src={down} alt="down arrow" className={shopStyles.down_icon} />
                </div>
              </div>
            </div>
            <div className="horizontal_line" />
            <div className="row-gap-4 column-gap-2 row flex-wrap my-4 px-3 px-md-4">
              {allProducts?.map((item) => <ProductItem item={item} key={item.id} style={`col-5 col-lg-3 flex-grow-1`} />)}
            </div>
          </div>
        </div>
        {/* may_like */}
        <section className={cartStyles.may_like}>
          <div className="container">
            <p className={cartStyles.may_title}>you may like</p>
            <div className={`${cartStyles.likes_box} d-flex mt-0`} onWheel={(event) => handleWheel(event, likesRef)} ref={likesRef}>
              {LIKESDATA.map((item) => <MayLikeItem item={item} />)}
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default Shop;
