import React from "react";
import tradingStyles from './styles.module.css'
import GPStyles from '../GoldPrice/styles.module.css'
import { AppBanner } from "../../components";
import { Line } from "react-chartjs-2";
import { CategoryScale, Chart } from "chart.js";
import { tradeBanner, service3, balanceCard, add, info } from "../../assets";

Chart.register(CategoryScale);

function GoldPriceHistory() {
  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "June"];
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "",
        backgroundColor: '#FCC65B80',
        borderColor: "#A7833F",
        data: [2020, 2030, 2020, 2060, 2040, 2050],
        fill: false,
      },
    ],
  };

  return (
    <main className="bg_dark">
      <div className="mt-lg-4">
        <AppBanner url={tradeBanner} title={'gold price history '} />
      </div>
      {/* balance_section */}
      <section className={`${tradingStyles.balance_section} py-4 my-5 mt-lg-0`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className={`${tradingStyles.card_container} col-12 col-md-5 col-lg-4 me-md-4 d-flex justify-content-center align-items-center`} style={{ backgroundImage: `url(${balanceCard})` }}>
              <div className={tradingStyles.balance_content}>
                <p className={`${tradingStyles.balance_txt} mb-1`}>Your current Balance</p>
                <p className={`${tradingStyles.balance_price} mb-0`}>16,5486 EGP</p>
              </div>
            </div>
            <div className="col-12 col-md-3 d-flex align-items-center justify-content-center mt-4 mt-md-0">
              <div className={tradingStyles.add_box}>
                <img src={add} alt="balance card" className="img" />
              </div>
                <p className={`${tradingStyles.add_txt} mb-0 ms-2`}>add money</p>
            </div>
          </div>
        </div>
      </section>
      {/* charts_section */}
      <section className={`${tradingStyles.charts_section} pt-5 pb-1`}>
        <div className="container">
          <div className={GPStyles.prices_title_box}>
            <h3 className={GPStyles.prices_title}>
              international gold price <span className='span'>per</span> ounce
            </h3>
          </div>
          <div className={`${GPStyles.charts_container} ${tradingStyles.charts_container}`}>
            <div className={`${GPStyles.chart_upper} d-flex justify-content-between align-items-center`}>
              <div>
                <p className={GPStyles.charts_title}>international gold price chart</p>
                <p className={GPStyles.charts_txt}>gold price <span className='span'>per</span> ounce</p>
              </div>
              <div className={GPStyles.charts_icon_box}>
                <img src={info} alt="help info" className="img" />
              </div>
            </div>
            <div className={`${GPStyles.line_chart_box}`}>
              <Line
                data={chartData}
                className={GPStyles.line_chart}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        color: '#FCC65B50',
                        tickBorderDash: [0.01, 0.01],
                      },
                      ticks: {
                        color: '#FCC65B80',
                      },
                    },
                    y: {
                      grid: {
                        color: '#FCC65B50',
                        tickBorderDash: [0.01, 0.01],
                      },
                      ticks: {
                        color: '#FCC65B',
                        callback: function (value) {
                          return value;
                        },
                      },
                      min: 2010,
                      max: 2070,
                    }
                  }
                }} />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default GoldPriceHistory;
